import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Badge} from "primereact/badge";
import {Dropdown} from "primereact/dropdown";
import {classNames} from "primereact/utils";
import {getUserStatus, getUserStatuses, updateUserStatus} from "../../../redux/api/apiUsersList";
import {initialAccountData} from "../../../redux/actions/actions";
import {setRolesVisible} from "../../../redux/actions/actionsRoles";
import {useNavigate} from "react-router-dom";
import {ERROR_ACCOUNT_WEB_LOGIN} from "../../../redux/types/types";
import {stopGlobalJsSIP} from "../../JsSip/GlobalJsSIP";
import {Button} from "primereact/button";
import InternetStatus from "../InternetStatus";
import { setReconnectSession} from "../../../redux/actions/actionsWebRTC";

const InlineProfileMenu = (props: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(state => state.UsersReducer.userData);
    const statusHubError = useAppSelector(state => state.WebRTCReducer.statusHubError);
    const monitoringHubError = useAppSelector(state => state.WebRTCReducer.monitoringHubError);
    // @ts-ignore
    const userStatus = useAppSelector(state => state.UsersListReducer.userStatus);
    const activeSession = useAppSelector(state => state.WebRTCReducer.activeSession);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [activeClick, setActiveClick] = useState(false);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const hash = window.location.hash;
    const statuses = useAppSelector(state => {
        // @ts-ignore
        const currentStatuses = state.UsersListReducer.statuses;
        const additionalStatus = {
            id: 1,
            externalName: "LOGOUT",
            statusName: "Logout",
            displayOrder: 1,
            statusColor: "rgb(177, 177, 177)",
            statusImagePath: null,
            description: "",
            isAuto: false
        };
        if (currentStatuses?.length) {
            return [...currentStatuses, additionalStatus];
        } else {
            return [additionalStatus];
        }
    });
    const dropdownRef = useRef<any>(null);
    const recallNumber = useAppSelector(state => state.WebRTCReducer.recallNumber);

    useEffect(() => {
        dispatch(getUserStatuses(jwtToken?.jwtToken));
        dispatch(getUserStatus(jwtToken?.jwtToken))
    }, []);

    useEffect(() => {
        if (recallNumber) {
            if (props.profileMenuActiveChange) {
                props.profileMenuActiveChange(true);
            }
        }
    }, [recallNumber]);

    const handleBadgeClick = () => {
        if (dropdownRef.current) {
            dropdownRef.current.show();
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleStatusChange = (event: any) => {
        let data = {
            userId: user?.userId,
            statusesId: event.value
        }
        if (event.value !== 1) {
            dispatch(updateUserStatus(data, jwtToken?.jwtToken, null, true));
        } else {
            dispatch(updateUserStatus(data, jwtToken?.jwtToken, null, true));
            navigate('/login');
            stopGlobalJsSIP();
            localStorage.clear();
            localStorage.removeItem('persist:callcenter:user');
            dispatch(initialAccountData());
            dispatch(setRolesVisible(null));
            dispatch({type: ERROR_ACCOUNT_WEB_LOGIN, payload: {"statusCode": null, "title": null, "message": null, "data": null}})
        }
    };

    const statusClass = classNames({
        'p-badge status-unsuccessful': userStatus === 'Unsuccess',
        'p-badge status-success': userStatus
    });

    const getStatusNameById = (id: number) => {
        const selectedStatus = statuses.find((status: { id: number; }) => status.id === id);
        const replaceStatusName = selectedStatus?.statusName?.replace(/Copy.*/, 'Copy...');
        return replaceStatusName ? replaceStatusName : '';
    }

    function customItemTemplate(option: any) {
        return (<>
            {option?.id !== 1 ?<div className="custom-dropdown-option">
                {option.statusName}
            </div> :
                <ul className="list-none p-0"><li className="layout-inline-menu-action-item">
                <button className="p-link text-white">
                    <i className="pi pi-fw pi-sign-out mr-2"></i>
                    <span>{option.statusName}</span>
                </button>
                </li></ul>}
            </>
        );
    }

    const filteredStatusList = statuses?.filter((status: { isAutoStatus: boolean; }) => !status.isAutoStatus);

    const clickActive = () => {
        if (props.profileMenuActiveChange) {
            props.profileMenuActiveChange(true);
        }
        setActiveClick(true);
        if (!activeSession) {
            dispatch(setReconnectSession(true));
        }
    };

    return (
        <>
            <div className='layout-inline-menu flex align-items-center relative inlineProfile'>
                <div className="layout-inline-menu-action p-link pt-1">
                    {monitoringHubError && hash === '#/monitoring' && <div className="routerContainer flex relative border-circle justify-content-center align-items-center signal-strength-offline mr-2">
                        <i style={{color: "red"}} className="pi pi-chart-bar text-xl"></i>
                    </div>}
                    {statusHubError &&<div className="routerContainer flex relative border-circle justify-content-center align-items-center signal-strength-offline mr-2">
                        <i style={{color: "red"}} className="pi pi-verified text-xl"></i>
                    </div>}
                    <InternetStatus/>
                    {activeSession ?
                        <Button icon="pi pi-phone"  className="text-xl phone-icon outline-none shadow-none border-none cursor-pointer p-button-success text-white mx-2 border-circle" style={{backgroundColor: 'rgb(76, 175, 80)', width: '40px', height: '40px', fontSize: '40px'}} onClick={clickActive}></Button>
                    : <Button icon="pi pi-phone"  className="text-xl phone-icon outline-none shadow-none cursor-pointer p-button-success text-white mx-2 border-circle relative overflow-visible flex justify-content-center" style={{backgroundColor: '#b3bac5', width: '40px', height: '40px', fontSize: '40px', border: '1px solid red'}} onClick={clickActive}><i className="offline-icon-phone pi pi-times"></i></Button>}
                    <button className="border-circle outline-none shadow-none border-none cursor-pointer flex align-items-center relative p-0 mr-2" style={{backgroundColor: '#b3bac5', width: '40px', height: '40px', fontSize: '40px', objectFit: 'cover'}} onClick={props.profileMenuActiveChange}><img style={{width: '40px', height: '40px'}} src={"assets/layout/images/default.svg"} alt="avatar"/></button>
                    <div className="flex flex-column">
                        <div className="flex mb-2">
                            {user ? <span className="layout-inline-menu-text text-lg ml-2">{user?.userName}</span> : <span className="layout-inline-menu-text">Unknown</span>}
                        </div>
                        {userStatus && userStatus?.statusColor && windowWidth > 576 && <div className="flex mx-1 p-0" style={{minWidth: '160px'}}>
                            <Badge onClick={handleBadgeClick} value={getStatusNameById(userStatus?.statusesId)} className={`${statusClass} text-white customer-badge px-2 text-xs p-0 cursor-pointer`} style={{borderRadius: '6px 0 0 6px', backgroundColor: `${userStatus?.statusColor}`}}/>
                            <div className="status-selector">
                                <Dropdown
                                    ref={dropdownRef}
                                    value={userStatus?.statusesId}
                                    options={filteredStatusList}
                                    onChange={handleStatusChange}
                                    filter
                                    height={300}
                                    showClear
                                    panelClassName="statusName"
                                    optionLabel="statusName"
                                    optionValue="id"
                                    style={{borderRadius: '0 6px 6px 0', borderColor: userStatus?.statusColor}}
                                    className={classNames(`statusName`, {'p-inputtext-filled h-full shadow-none relative': userStatus})}
                                    // disabled={!hasUsersManagerUpdatePermission}
                                    itemTemplate={customItemTemplate}
                                />
                            </div>
                        </div>}
                    </div>

                </div>
            </div>
        </>
    );
};

export default InlineProfileMenu;
