import React, {useEffect, useRef, useState} from 'react';
import JsSIP from 'jssip';
import {Button} from "primereact/button";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import PhoneKeyboard from "./PhoneKeyboard";
import {Toast} from "primereact/toast";
import {CSSTransition} from 'react-transition-group';
import {callOptions, DEV, STAGE, WEBRTC_WSS_URL, WSS_URL, WWS_WEBRTC} from "../../redux/config";
import {getUsersWebRTC, sendCallEvents} from "../../redux/api/apiWebRTC";
import {AutoComplete} from "primereact/autocomplete";
import {ConfirmDialog} from "primereact/confirmdialog";
import {checkUserForCall} from "../../redux/api/api";
import {changeSession, getGlobalJsSIP, initGlobalJsSIP, stopGlobalJsSIP} from "./GlobalJsSIP";
import {
    checkSipConnection,
    clearRecallSession,
    sessionReconnected,
    setActiveSession, setMonitoringHubError, setStatusHubError, setUserDataHubError
} from "../../redux/actions/actionsWebRTC";
import {TabView, TabPanel} from 'primereact/tabview';
import MonitoringSipDetails from "../Monitoring/MonitoringList/MonitoringSipDetails";
import {getEventMonitorToUserDetail, getOwnerTypeDictionary} from "../../redux/api/apiMonitoringList";
import {HubConnectionBuilder} from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import {DecodedToken} from "../../types/types";
import {jwtDecode} from "jwt-decode";
import {clearMonitoringSipDetails} from "../../redux/actions/actionsMonitoringList";
import {
    CLEAR_CONSULTATION_FOR_SIP,
    GET_CONSULTATION_FOR_SIP,
    GET_MONITORING,
    GET_MONITORING_FOR_SIP,
    GET_MONITORINGS
} from "../../redux/types/typesMonitoringList";
import ConsultationSipDetails from "../Monitoring/MonitoringList/ConsultationSipDetails";

interface NetworkInformation extends EventTarget {
    downlink: number;
    effectiveType: string;
}

const SIPCallComponent = (props: any) => {
    const dispatch = useAppDispatch();
    const intervalIdRef = useRef<any>(null);
    const internetConnection = (navigator as any).connection as NetworkInformation | undefined;
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const checkUserForCalling = useAppSelector(state => state.UsersListReducer.checkUserForCalling);
    const searchResults = useAppSelector(state => state.WebRTCReducer.searchResults);
    const webRTCError = useAppSelector(state => state.WebRTCReducer.error);
    const [status, setStatus] = useState<string>('Idle');
    const [call, setCall] = useState<any>(null);
    const [consultationCall, setConsultationCall] = useState<any>(false);
    const [newCallInfo, setNewCallInfo] = useState<any>(null);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [queueCallId, setQueueCallId] = useState<number | null>(null);
    const [isConsultationCall, setIsConsultationCall] = useState<boolean | any>(null);
    const [referCall, setReferCall] = useState<boolean>(false);
    const [autocompleteChange, setAutocompleteChange] = useState<boolean>(false);
    const [ua, setUA] = useState<JsSIP.UA | null>(null);
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
    const [errorJsSip, setErrorJsSip] = useState<boolean>(false);
    const [closeCallButton, setCloseCallButton] = useState<boolean>(false);
    const toast = useRef<any>(null);
    const keyboardRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
    const [showDialog, setShowDialog] = useState(false);
    const [incomingSession, setIncomingSession] = useState<any>(null);
    const [volume, setVolume] = useState(0.5);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const holdMusicAudioRef = useRef<HTMLAudioElement | null>(null);
    const [isCallHold, setIsCallHold] = useState<boolean>(false);
    const [isCallMute, setIsCallMute] = useState<boolean>(false);
    const [isConsultationCallHold, setIsConsultationCallHold] = useState<boolean>(false);
    const [isConsultationCallMute, setIsConsultationCallMute] = useState<boolean>(false);
    const [lastCallNumber, setLastCallNumber] = useState<string | null>(null);
    const ringtoneAudioRef = useRef<HTMLAudioElement | null>(null);
    const endCallAudioRef = useRef<HTMLAudioElement | null>(null);
    const [callDuration, setCallDuration] = useState<number>(0);
    const [callHoldDuration, setCallHoldDuration] = useState<number>(0);
    const [callMuteDuration, setCallMuteDuration] = useState<number>(0);
    const [consultationCallDuration, setConsultationCallDuration] = useState<number>(0);
    const [consultationCallHoldDuration, setConsultationCallHoldDuration] = useState<number>(0);
    const [consultationCallMuteDuration, setConsultationCallMuteDuration] = useState<number>(0);
    const [incomingCall, setIncomingCall] = useState<boolean>(false);
    const [callDirection, setCallDirection] = useState<any>('');
    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [newValue, setNewValue] = useState('');
    const [flowUuid, setFlowUuid] = useState('');
    const [groupFlowUuid, setGroupFlowUuid] = useState('');
    const muteId = useAppSelector(state => state.WebRTCReducer.muteId);
    const muteConsultationId = useAppSelector(state => state.WebRTCReducer.muteConsultationId);
    const activeSession = useAppSelector(state => state.WebRTCReducer.activeSession);
    const recallNumber = useAppSelector(state => state.WebRTCReducer.recallNumber);
    const recallNumberSessionId = useAppSelector(state => state.WebRTCReducer.sessionId);
    const recallNumberChanged = useAppSelector(state => state.WebRTCReducer.recallNumberChanged);
    const originalTitle = document.title;
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    const [visibleConsultation, setVisibleConsultation] = useState(false);
    const [visible, setVisible] = useState(false);
    const monitoringSipData = useAppSelector(state => state.MonitoringListReducer?.monitoringSipData);
    const consultationSipData = useAppSelector(state => state.MonitoringListReducer?.consultationSipData);
    const reconnectSession = useAppSelector(state => state.WebRTCReducer.reconnectSession);
    const restartSipConnection = useAppSelector(state => state.WebRTCReducer.checkSipConnection);
    const monitoringHubError = useAppSelector(state => state.WebRTCReducer.monitoringHubError);
    const statusHubError = useAppSelector(state => state.WebRTCReducer.statusHubError);
    const [userDataHubConnection, setUserDataHubConnection] = useState(null) as any;
    const [userDataCallDetails, setUserDataCallDetails] = useState<any>(null);
    const [groupIdForConsultation, setGroupIdForConsultation] = useState<any>(null);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [selectedConsultationData, setSelectedConsultationData] = useState<any | null>(null);
    const [queueId, setQueueId] = useState<any | null>(null);
    const toastError = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0); // Индекс активной вкладки
    const [consultationReferCall, setConsultationReferCall] = useState(false);
    const hash = window.location.hash;

    //transfer for number

    // let data = {
    //     "userName": phoneNumber,
    //     "lastName": "",
    //     "firstName": "",
    //     "middleName": "",
    //     "isLogin": true,
    //     "mainBranchId": 0,
    //     isInput: true
    // }
    let updatedResults = searchResults;
    //transfer for number
    // if (phoneNumber) {
    //     updatedResults = [data, ...searchResults];
    // }

    const selectTab = (index: number) => {
        setActiveIndex(index);
    };
    // @ts-ignore
    const userStatus = useAppSelector(state => state.UsersListReducer.userStatus);
    let decodedToken: DecodedToken;
    if (jwtToken?.jwtToken) {
        decodedToken = jwtDecode(jwtToken?.jwtToken);
    }
    const [muteCallData] = useState({
        id: 0,
        flow_uuid: null,
        Sip_auth_username: user?.userName,
        timeStart: null,
        call_id: null
    });

    const [unMuteCallData] = useState({
        id: 0,
        flow_uuid: null,
        Sip_auth_username: user?.userName,
        timeEnd: null,
        call_id: null
    });

    const [microphoneAccess, setMicrophoneAccess] = useState('unknown'); // 'granted', 'denied', 'prompt', 'unknown'

    useEffect(() => {
        let permissionStatus: PermissionStatus;

        const checkMicrophoneAccess = async () => {
            try {
                permissionStatus = await navigator.permissions.query({
                    name: 'microphone' as PermissionName,
                });

                setMicrophoneAccess(permissionStatus.state);

                permissionStatus.onchange = () => {
                    setMicrophoneAccess(permissionStatus.state);
                };
            } catch (error) {
                console.error('Ошибка проверки доступа к микрофону:', error);
            }
        };

        checkMicrophoneAccess();

        return () => {
            if (permissionStatus) {
                permissionStatus.onchange = null;
            }
        };
    }, []);

    useEffect(() => {
        dispatch(getOwnerTypeDictionary(jwtToken?.jwtToken));
        setCall(null);
        setConsultationCall(null);
    }, []);

    useEffect(() => {
        const checkDirection = /^((8|\+7|\+3)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

        if (checkUserForCalling && !referCall && !call) {
            if (queueId) {
                makeCall(queueId);
            } else {
                if (!checkDirection.test(newValue)) {
                    setCallDirection('p2p');
                } else {
                    setCallDirection('outbound');
                }
                makeCall(newValue);
            }
        } else if (checkUserForCalling && !referCall && call) {
            if (queueId) {
                makeConsultationCall(queueId);
            } else {
                call.hold();
                makeConsultationCall(newValue);
            }
            holdCall();
        } else if (checkUserForCalling && referCall && call) {
            if (queueId) {
                handleReferCall(queueId, queueId);
            } else {
                if (!checkDirection.test(newValue)) {
                    setCallDirection('p2p');
                } else {
                    setCallDirection('outbound');
                }
                handleReferCall(newValue, queueCallId);
            }
        }
    }, [checkUserForCalling]);

    useEffect(() => {
        if (!call) {
            if (recallNumber) {
                setNewValue(recallNumber);
                setPhoneNumber(recallNumber)
                if (recallNumberSessionId) {
                    setCallDirection('с2с');
                }
                setReferCall(false);
                dispatch(checkUserForCall(recallNumber, jwtToken?.jwtToken));
            }
        }
    }, [recallNumber, recallNumberChanged]);

    useEffect(() => {
        if ((call || incomingCall) && consultationCall) {
            setCloseCallButton(true);
        } else {
            setCloseCallButton(false);
        }
    }, [call, consultationCall, incomingCall]);

    useEffect(() => {
        if (incomingSession && incomingSession?._request?.headers?.['X-Direction']?.[0]?.raw === "c2c" && incomingSession?._request?.headers?.['X-Calltype']?.[0]?.raw === "external") {
            props.profileMenuActiveChange(true);
            setConfirmDialogVisible(false);
            if (ringtoneAudioRef.current) {
                ringtoneAudioRef.current.pause();
                ringtoneAudioRef.current.currentTime = 0;
            }
            acceptCall();
        } else if (incomingSession) {
            setShowDialog(true);
            props.profileMenuActiveChange(true);
        } else {
            setShowDialog(false);
        }
    }, [incomingSession]);

    useEffect(() => {
        const updateDurations = () => {
            if (call) {
                setCallDuration(prevDuration => prevDuration + 1);
            }

            if (isCallHold) {
                setCallHoldDuration(prevDuration => prevDuration + 1);
            }

            if (isCallMute) {
                setCallMuteDuration(prevDuration => prevDuration + 1);
            }
        };

        const interval = setInterval(updateDurations, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [call, isCallHold, isCallMute]);

    useEffect(() => {
        const updateDurations = () => {
            if (consultationCall) {
                setConsultationCallDuration(prevDuration => prevDuration + 1);
            }

            if (isConsultationCallHold) {
                setConsultationCallHoldDuration(prevDuration => prevDuration + 1);
            }

            if (isConsultationCallMute) {
                setConsultationCallMuteDuration(prevDuration => prevDuration + 1);
            }
        };

        const interval = setInterval(updateDurations, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [consultationCall, isConsultationCallHold, isConsultationCallMute]);

    JsSIP.debug.disable();

    const [networkInfo, setNetworkInfo] = useState({
        downlink: internetConnection?.downlink ?? null,
        effectiveType: internetConnection?.effectiveType ?? null
    });

    const getSignalStrength = (online: boolean, downlink: number | null, effectiveType: string | null): number => {
        if (!online) return 0; // Оффлайн
        if (downlink === null || effectiveType === null) return 1;
        if (downlink < 1 || effectiveType?.includes('2g')) return 1;
        if (downlink < 2.5) return 2;
        return 3;
    };

    useEffect(() => {
        const updateNetworkInfo = () => {
            setIsOnline(navigator.onLine);
            setNetworkInfo({
                downlink: internetConnection?.downlink ?? null,
                effectiveType: internetConnection?.effectiveType ?? null
            });
        };

        window.addEventListener('online', updateNetworkInfo);
        window.addEventListener('offline', updateNetworkInfo);
        internetConnection?.addEventListener('change', updateNetworkInfo);

        return () => {
            window.removeEventListener('online', updateNetworkInfo);
            window.removeEventListener('offline', updateNetworkInfo);
            internetConnection?.removeEventListener('change', updateNetworkInfo);
        };
    }, [internetConnection]);

    const signalStrength: any = getSignalStrength(isOnline, networkInfo.downlink, networkInfo.effectiveType);
    let strengthClass = '';

    switch (signalStrength) {
        case 1:
            strengthClass = 'weak';
            break;
        case 2:
            strengthClass = 'medium';
            break;
        case 3:
            strengthClass = 'strong';
            break;
        default:
            strengthClass = 'signal-icon-offline';
            break;
    }

    const startUserDataHubConnection = () => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${WSS_URL}UserDataHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => jwtToken?.jwtToken || ""
            })
            .withAutomaticReconnect([0, 3000, 5000, 10000, 20000, 30000, 50000, 60000])
            .build();

        connection.serverTimeoutInMilliseconds = 60000;

        connection.onreconnected(() => {
            console.log(`[${new Date().toISOString()}] Information: WebSocket reconnected to ${WSS_URL}UserDataHub`);
            connection.invoke("UserConnected", decodedToken[`x-access-userid`]).then(() => {
                dispatch(setUserDataHubError(false));
            })
                .catch(() => {
                    dispatch(setStatusHubError(true));
                }).catch(err => console.error(err));
            dispatch(setUserDataHubError(false));
        });

        connection.onclose(async () => {
            console.log(`[${new Date().toISOString()}] Information: WebSocket disconnected. Attempting to reconnect to ${WSS_URL}UserDataHub`);
            dispatch(setUserDataHubError(true));
        });

        connection.onreconnecting(() => {
            console.warn(`[${new Date().toISOString()}] Warning: WebSocket reconnecting...`);
            dispatch(setUserDataHubError(true));
        });

        connection.start()
            .then(() => {
                dispatch(setUserDataHubError(false));
                connection.on('CallEventInfo', updateStatus => {
                    setUserDataCallDetails(JSON.parse(updateStatus));
                });
                connection.invoke("UserConnected", decodedToken[`x-access-userid`]).then(() => {
                    dispatch(setUserDataHubError(false));
                })
                    .catch(() => {
                        dispatch(setUserDataHubError(true));
                    });
                changeSession();
            })
            .catch((e) => {
                dispatch(setUserDataHubError(true));
            });

        setUserDataHubConnection(connection);

        return connection;
    };

    let connectionUserHub: any;

    useEffect(() => {
        if (!reconnectSession) {
            connectionUserHub = startUserDataHubConnection();
            return () => {
                connectionUserHub.stop();
            };
        } else {
            if (userDataHubConnection && userDataHubConnection.state === 'Disconnecting') {
                userDataHubConnection.stop();
                connectionUserHub = startUserDataHubConnection();
            }
        }
    }, [reconnectSession]);

    useEffect(() => {
        if (isOnline) {
            if (userDataHubConnection) {
                userDataHubConnection.stop();
                connectionUserHub = startUserDataHubConnection();
                dispatch(setStatusHubError(false));
            } else {
                dispatch(setStatusHubError(true));
            }
        }
    }, [isOnline]);

    useEffect(() => {
        const getUserDataHubConnection = () => {
            if (isOnline) {
                dispatch(setUserDataHubError(!(userDataHubConnection?.state === 'Connecting' || userDataHubConnection?.state === 'Connected')));
            } else {
                dispatch(setUserDataHubError(true));
            }
        };

        const intervalId = setInterval(getUserDataHubConnection, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [userDataHubConnection, isOnline]);

    function keysFirstLetterToLowerCase(obj: any): any {
        if (Array?.isArray(obj)) {
            return obj.map(item => keysFirstLetterToLowerCase(item));
        } else if (obj !== null && typeof obj === 'object') {
            return Object.keys(obj).reduce((acc: any, key: string) => {
                const firstLetterLowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
                acc[firstLetterLowerCaseKey] = keysFirstLetterToLowerCase(obj[key]);
                return acc;
            }, {});
        }
        return obj;
    }

    useEffect(() => {
        if (activeIndex === 0 && monitoringSipData?.callDetailId) {
            dispatch(getEventMonitorToUserDetail(monitoringSipData?.callDetailId, jwtToken?.jwtToken, true));
        } else if (activeIndex === 1 && consultationSipData?.callDetailId) {
            dispatch(getEventMonitorToUserDetail(consultationSipData?.callDetailId, jwtToken?.jwtToken, true, true));
        }
    }, [activeIndex]);

    useEffect(() => {
        if (userDataCallDetails && (call || incomingCall) && !consultationCall) {
            setSelectedRowData(keysFirstLetterToLowerCase(userDataCallDetails));
            setVisible(true);
            dispatch({type: GET_MONITORING_FOR_SIP, payload: keysFirstLetterToLowerCase(userDataCallDetails)});
            let details = keysFirstLetterToLowerCase(userDataCallDetails);
            setGroupIdForConsultation(details?.groupSessionId);
        }

        if (userDataCallDetails && (call || incomingCall) && consultationCall) {
            setSelectedConsultationData(keysFirstLetterToLowerCase(userDataCallDetails));
            setVisibleConsultation(true);
            dispatch({type: GET_CONSULTATION_FOR_SIP, payload: keysFirstLetterToLowerCase(userDataCallDetails)});
        }

    }, [userDataCallDetails, call, incomingCall]);

    const closeDetailVisible = () => {
        setVisible(false);
        setSelectedRowData(null);
    }


    const checkUser = (value?: any, isRefer?: any, queueId?: number | null) => {
        let newValue = value.replace(/[()\-\s\n\t\r+,;!'"?:$%&/]/g, '');


        if (value) {
            setNewValue(newValue);
            setPhoneNumber(newValue);

            if (queueId) {
                dispatch(checkUserForCall(queueId, jwtToken?.jwtToken));
                setQueueId(queueId);
            } else {
                dispatch(checkUserForCall(newValue, jwtToken?.jwtToken));
                setQueueId(null);
            }

            setReferCall(isRefer);
        } else {
            showError();
        }
    };

    const formatCallDuration = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const stopAllMicrophones = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioInputs = devices.filter(device => device.kind === "audioinput");

            if (audioInputs.length === 0) {
                return;
            }

            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            audioStream.getTracks().forEach(track => {
                track.stop();
            });

        } catch (error) {
            console.log(error);
        }
    };

    const trackStop = () => {
        stopAllMicrophones();

        if (audioRef.current) {
            const stream = audioRef.current.srcObject as MediaStream;

            if (stream) {
                stream.getTracks().forEach(track => {
                    track.stop();
                    stream.removeTrack(track);
                });

                audioRef.current.srcObject = null;
            }
        }

        if (call && call.connection) {
            call.connection.getSenders().forEach((sender: any) => {
                if (sender.track) {
                    sender.track.stop();
                    if (call.connection.signalingState !== "closed") {
                        sender.replaceTrack(null);
                        call.connection.removeTrack(sender);
                    }}
            });

            call.connection.close();
        }
    }

    useEffect(() => {
            JsSIP.debug.disable();
            JsSIP.debug.log = console.log;
            try {
                const jsSIPConfig = {
                    uri: `sip:${user?.userName}@${WWS_WEBRTC}`,
                    password: `${user?.hash}`,
                    sockets: [new JsSIP.WebSocketInterface(`${WEBRTC_WSS_URL}`)],
                    session_timers: false,
                };

                initGlobalJsSIP(jsSIPConfig);

                let ua = getGlobalJsSIP();
                if (reconnectSession && !activeSession) {
                    stopGlobalJsSIP();
                    initGlobalJsSIP(jsSIPConfig);
                    ua = getGlobalJsSIP();
                    changeSession();
                    dispatch(setActiveSession(true));
                    dispatch(sessionReconnected(true));
                }
                setUA(ua);

                ua.on('registered', () => {
                    setStatus('Registered');
                });

                const timeoutId = setTimeout(() => {
                    dispatch(checkSipConnection(true));
                    setErrorJsSip(true);
                }, 5000);

                ua.on('newMessage', (e: any) => {
                    clearTimeout(timeoutId);
                    let message = JSON.parse(e?.request?.body);
                    let urls: string;
                    const protocol = message?.data?.secure ? "turns" : "turn";
                    urls = `${protocol}:${message?.data?.ip}:${message?.data?.port}?transport=${message?.data?.transport}`;
                    if (message.data) {
                        let iceServer = {
                            urls: urls,
                            username: message?.data?.user,
                            credential: message?.data?.pass
                        };
                        if (message.cmd === 'turninfo') {
                            callOptions.pcConfig.iceServers = [];
                            callOptions?.pcConfig?.iceServers.push(iceServer);
                        }
                        if (message.data.audio) {
                            callOptions.mediaConstraints.audio = {
                                ...callOptions.mediaConstraints.audio,
                                ...Object.fromEntries(Object.entries(message.data.audio).map(([key, value]) => [key, value]))
                            };
                        }

                        dispatch(checkSipConnection(false));
                        clearInterval(intervalIdRef.current);
                        intervalIdRef.current = null;
                        setErrorJsSip(false);
                    } else {
                        showTurnDataError();
                        setErrorJsSip(true);
                    }
                });

                ua.on('unregistered', () => {
                    setStatus('Unregistered');
                });

                ua.on('connected', () => {
                    setStatus('Connected');
                });

                ua.on('disconnected', () => {
                    setStatus('Disconnected');
                    setErrorJsSip(true);
                });

                ua.on('error', (error: any) => {
                    setErrorJsSip(true);
                });

                ua.on('newRTCSession', (e: { session: any; }) => {
                    function onNewNotification() {
                        document.title = '🔔 Вхідний дзвінок!';
                    }

                    window.onfocus = function () {
                        document.title = originalTitle;
                    };

                    const newSession = e.session;

                    if (newSession && newSession.direction !== 'incoming') {
                        setNewCallInfo(newSession);
                        setIncomingCall(false);
                        newSession.connection.addEventListener('track', (e: any) => {
                            if (e.track.kind === "audio" && audioRef.current) {

                                let stream = audioRef.current.srcObject as MediaStream;
                                if (!stream) {
                                    stream = new MediaStream();
                                    audioRef.current.srcObject = stream;
                                }

                                stream.getAudioTracks().forEach(track => stream.removeTrack(track));
                                stream.addTrack(e.track);

                                setTimeout(() => {
                                    if (audioRef.current && newSession) {
                                        audioRef.current.play().catch(err => console.error(err));
                                    }
                                }, 100);
                            }
                        });
                    }

                    if (newSession.direction === 'incoming') {
                        setIncomingCall(true);
                        setCallDirection(newSession?._request?.headers?.['X-Direction']?.[0].raw)
                        if (ringtoneAudioRef) {
                            ringtoneAudioRef?.current?.play().catch(() => {
                                setConfirmDialogVisible(true);
                            });
                        }
                        onNewNotification();
                        setIncomingSession(newSession);
                        setFlowUuid(newSession?._request?.headers?.['X-Flow-Uuid']?.[0]?.raw);
                        setShowDialog(true);

                        newSession.on('ended', () => {
                            setIncomingSession(null);
                            setStatus('Call Ended');
                            setCall(null);
                            setIsCallHold(false);
                            setIsCallMute(false);
                            setVisible(false);
                            toggleHoldMusic(false);
                            closeDetails();
                            if (ringtoneAudioRef.current) {
                                ringtoneAudioRef.current.pause();
                                ringtoneAudioRef.current.currentTime = 0;
                            }
                            //TEST FOR DEVICE CHANGE
                            trackStop();
                            if (consultationCall) {
                                setActiveIndex(1);
                            }
                            document.title = originalTitle;
                        });

                        newSession.on('failed', function () {
                            setIncomingSession(null)
                            setStatus('Call failed');
                            setCall(null);
                            closeDetails();
                            setIsCallHold(false);
                            setIsCallMute(false);
                            setVisible(false);
                            toggleHoldMusic(false);
                            if (ringtoneAudioRef.current) {
                                ringtoneAudioRef.current.pause();
                                ringtoneAudioRef.current.currentTime = 0;
                            }
                            //TEST FOR DEVICE CHANGE
                            document.title = originalTitle;
                            if (consultationCall) {
                                setActiveIndex(1);
                            }
                            trackStop();
                        });
                    } else if (newSession.direction === 'outgoing') {
                        setStatus('Outgoing Call');
                    }
                });

                ua.start();

                const restartUa = () => {
                    console.log(new Date() + ' Attempt to reconnect to SIP');
                    ua.start();
                };

                if (restartSipConnection) {
                    if (!intervalIdRef.current) {
                        intervalIdRef.current = setInterval(restartUa, 5000);
                    }
                } else {
                    if (intervalIdRef.current) {
                        clearInterval(intervalIdRef.current);
                        intervalIdRef.current = null;
                    }
                }

            } catch (error) {
                setErrorJsSip(true);
            }

            return () => {
                if (intervalIdRef.current) {
                    clearInterval(intervalIdRef.current);
                    intervalIdRef.current = null;
                }
                ua?.stop();
            };
        }, [reconnectSession, restartSipConnection]
    );

    useEffect(() => {
        const handleDeviceChange = async () => {
            if (!call && !incomingSession && !incomingCall) {
                return;
            }

            await new Promise(resolve => setTimeout(resolve, 1000));

            if (audioRef.current && (call || consultationCall)) {
                try {
                    let stream = new MediaStream();
                    audioRef.current.srcObject = stream;

                    const devices = await navigator.mediaDevices.enumerateDevices();

                    const activeInput = devices.find(d => d.kind === "audioinput" && d.deviceId !== "default");
                    // console.log(`🎤 - micro - ${activeInput?.label}`);

                    const outputDevices = devices.filter(d => d.kind === "audiooutput");
                    const activeOutput = outputDevices.length > 0 ? outputDevices[0] : null;
                    // console.log(`🔊 - audio - ${activeOutput?.label}`);

                    let newAudioTrack: MediaStreamTrack | any | null = null;
                    let newMicTrack: MediaStreamTrack | any | null = null;

                    if (call && call.connection) {
                        let attempts = 0;
                        while (attempts < 5) {
                            const receiver = call.connection.getReceivers().find((r: { track: { kind: string; }; }) => r.track?.kind === "audio");
                            if (receiver && (!newAudioTrack || receiver.track.id !== newAudioTrack.id)) {
                                newAudioTrack = receiver.track;
                                break;
                            }
                            await new Promise(resolve => setTimeout(resolve, 500));
                            attempts++;
                        }

                        if (activeInput) {
                            const newStream = await navigator.mediaDevices.getUserMedia({
                                audio: { deviceId: { exact: activeInput.deviceId } }
                            });

                            call.connection.getSenders().forEach((sender: { track: { kind: string; stop: () => void; }; }) => {
                                if (sender.track?.kind === "audio") {
                                    sender.track.stop();
                                }
                            });

                            newMicTrack = newStream.getAudioTracks()[0];

                            if (newMicTrack) {
                                const sender = call.connection.getSenders().find((s: { track: { kind: string; }; }) => s.track?.kind === "audio");
                                if (sender) {
                                    await sender.replaceTrack(newMicTrack);

                                    let confirmationAttempts = 0;
                                    while (confirmationAttempts < 5) {
                                        const updatedSender = call.connection.getSenders().find((s: { track: { kind: string; }; }) => s.track?.kind === "audio");
                                        if (updatedSender?.track?.id === newMicTrack.id) {
                                            break;
                                        }
                                        await new Promise(resolve => setTimeout(resolve, 500));
                                        confirmationAttempts++;
                                    }
                                } else {
                                    console.log("Не знайдено аудіотрек для оновлення");
                                }
                            }
                        }
                    }

                    if (newAudioTrack) {
                        stream.addTrack(newAudioTrack);
                    }

                    setTimeout(() => {
                        if (audioRef.current && (call || consultationCall)) {
                            audioRef.current.play().catch(err => console.log(err));
                        }
                    }, 700);

                    if ("setSinkId" in audioRef.current) {
                        setTimeout(async () => {
                            if (activeOutput) {
                                try {
                                    await (audioRef.current as any).setSinkId(activeOutput.deviceId);
                                } catch (err) {
                                    console.log(`Помилка установки пристрою виведення: ${err}`);
                                }
                            }
                        }, 1000);
                    }

                } catch (error) {
                    console.log(`Помилка при обробці пристроїв: ${error}`);
                }
            }
        };

        navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

        return () => {
            navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
        };
    }, [call, incomingSession, incomingCall, consultationCall]);

    window.addEventListener('offline', () => {
        setErrorJsSip(true);
        dispatch(setMonitoringHubError(true));
        dispatch(setStatusHubError(true));
    });

    useEffect(() => {
        if (errorJsSip) {
            dispatch(checkSipConnection(true));
        } else {
            dispatch(checkSipConnection(false));
        }
    }, [errorJsSip]);

    useEffect(() => {
        if (call) {
            call.on('ended', () => {
                setStatus('Call Ended');
                setCallDuration(0);
                setCallHoldDuration(0);
                setCallMuteDuration(0);
                if (consultationCall) {
                    setActiveIndex(1);
                }
                trackStop();
            });
        }
    }, [call]);

    const makeCall = (value?: any) => {
        if (ua && call === null && phoneNumber.trim() !== '') {
            setActiveIndex(0);
            setIsConsultationCall(false);
            let userPhone = phoneNumber
            if (value) {
                userPhone = value;
            }

            const createUuidv4 = () => {
                return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    // @ts-ignore
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            }

            let flowUuId = createUuidv4();
            callOptions.extraHeaders = [];

            if (recallNumberSessionId) {
                // @ts-ignore
                callOptions.extraHeaders.push(`X-group_flow_uuid: ${recallNumberSessionId}`);
                setGroupFlowUuid(recallNumberSessionId);
                dispatch(clearRecallSession());
                // @ts-ignore
                callOptions.extraHeaders.push(`X-campaign_id: callback::0`);
            }
            // @ts-ignore
            callOptions.extraHeaders.push(`X-flow_uuid: ${flowUuId}`);

            setFlowUuid(flowUuId)

            const destination = `sip:${userPhone}@${WWS_WEBRTC}`;
            // @ts-ignore
            const newCall = ua.call(destination, callOptions);

            setLastCallNumber(userPhone);
            setPhoneNumber('');

            newCall.on('connecting', () => {
                setStatus('Calling...');
            });

            newCall.on('icecandidate', (e: any) => {
                if (e.candidate.type == "relay" && e.candidate.relatedAddress == '0.0.0.0' && e.candidate.relatedPort == 0) {
                    e.ready();
                }
            });

            const terminateCallByError = (errorMessage: string) => {
                if (newCall) {
                    newCall.terminate();
                }
                setPhoneNumber('');
                setCall(null);
                closeDetails();
                setIsCallHold(false);
                setIsCallMute(false);
                setVisible(false);
                toggleHoldMusic(false);
                if (consultationCall) {
                    setActiveIndex(1);
                }

                toastError?.current?.show({severity: 'error', summary: errorMessage, detail: '', life: 5000});

            };

            let iceTimeout = setTimeout(() => {
                console.error(`TURN - error`)
                terminateCallByError('Дзвінок не створено! Помилка мережі. Перевірте підключення, або спробуйте пізніше.');
            }, 1500);

            newCall.connection.onicegatheringstatechange = function () {
                const gatheringState = newCall.connection.iceGatheringState;

                if (gatheringState === 'complete') {
                    clearTimeout(iceTimeout);
                }
            };

            newCall.on('accepted', () => {
                setStatus('Call Accepted');
            });

            newCall.on('ended', () => {
                setIncomingSession(null)
                setStatus('Call Ended');
                setCall(null);
                closeDetails();
                setIsCallHold(false);
                setIsCallMute(false);
                setVisible(false);
                toggleHoldMusic(false);
                setCallDuration(0);
                setCallHoldDuration(0);
                setCallMuteDuration(0);
                document.title = originalTitle;
                if (consultationCall) {
                    setActiveIndex(1);
                }
                trackStop();
            });

            newCall.on('failed', function () {
                setIncomingSession(null)
                setStatus('Call failed');
                setCall(null);
                closeDetails();
                setIsCallHold(false);
                setIsCallMute(false);
                setVisible(false);
                toggleHoldMusic(false);
                document.title = originalTitle;
                if (consultationCall) {
                    setActiveIndex(1);
                }
            });
        } else {
            showError();
        }
    };

    const makeConsultationCall = (value?: any) => {
        if (ua && call !== null && phoneNumber.trim() !== '') {
            let userPhone = phoneNumber
            if (value) {
                userPhone = value;
            }


            const createUuidv4 = () => {
                return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    // @ts-ignore
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            }

            let flowUuId = createUuidv4();
            callOptions.extraHeaders = [];

            if (groupIdForConsultation) {
                // @ts-ignore
                callOptions.extraHeaders.push(`X-group_flow_uuid: ${groupIdForConsultation}`);
                // @ts-ignore
                callOptions.extraHeaders.push(`X-campaign_id: consultation::0`);
                dispatch(clearRecallSession())
            }
            // @ts-ignore
            callOptions.extraHeaders.push(`X-flow_uuid: ${flowUuId}`);

            setFlowUuid(flowUuId)

            const destination = `sip:${userPhone}@${WWS_WEBRTC}`;
            // @ts-ignore
            const newCall = ua.call(destination, callOptions);
            setActiveIndex(1);

            setLastCallNumber(userPhone);
            setPhoneNumber('');

            newCall.on('connecting', () => {
                setStatus('Calling...');
            });

            newCall.on('icecandidate', (e: any) => {
                if (e.candidate.type == "relay" && e.candidate.relatedAddress == '0.0.0.0' && e.candidate.relatedPort == 0) {
                    e.ready();
                }
            });

            const terminateCallByError = (errorMessage: string) => {
                if (newCall) {
                    newCall.terminate();
                }
                setPhoneNumber('');
                setStatus('Call Ended');
                setConsultationCall(null);
                closeConsultationDetails();
                setIsConsultationCallHold(false);
                setIsConsultationCallMute(false);
                setVisibleConsultation(false);
                setConsultationCallDuration(0);
                setConsultationCallHoldDuration(0);
                setConsultationCallMuteDuration(0);
                document.title = originalTitle;
                trackStop();
                if (call) {
                    setActiveIndex(0);
                }
                toastError?.current?.show({severity: 'error', summary: errorMessage, detail: '', life: 5000});
            };

            let iceTimeout = setTimeout(() => {
                console.error(`TURN - error`)
                terminateCallByError('Дзвінок не створено! Помилка мережі. Перевірте підключення, або спробуйте пізніше.');
            }, 1500);

            newCall.connection.onicegatheringstatechange = function () {
                const gatheringState = newCall.connection.iceGatheringState;

                if (gatheringState === 'complete') {
                    clearTimeout(iceTimeout);
                }
            };

            newCall.on('accepted', () => {
                setStatus('Call Accepted');
            });

            newCall.on('ended', () => {
                setStatus('Call Ended');
                setConsultationCall(null);
                closeConsultationDetails();
                setIsConsultationCallHold(false);
                setIsConsultationCallMute(false);
                setVisibleConsultation(false);
                setConsultationCallDuration(0);
                setConsultationCallHoldDuration(0);
                setConsultationCallMuteDuration(0);
                document.title = originalTitle;
                trackStop();
                if (call) {
                    setActiveIndex(0);
                }
            });

            newCall.on('failed', function () {
                setStatus('Call Ended');
                setConsultationCall(null);
                closeConsultationDetails();
                setIsConsultationCallHold(false);
                setIsConsultationCallMute(false);
                setVisibleConsultation(false);
                setConsultationCallDuration(0);
                setConsultationCallHoldDuration(0);
                setConsultationCallMuteDuration(0);
                document.title = originalTitle;
                trackStop();
                if (call) {
                    setActiveIndex(0);
                }
            });
        } else {
            showError();
        }
    };

    useEffect(() => {
        if (!call && newCallInfo) {
            setCall(newCallInfo);
        } else if (call && newCallInfo) {
            setConsultationCall(newCallInfo);
        }
    }, [newCallInfo]);

    const endCall = () => {
        if (consultationCall) {
            setActiveIndex(1);
        }
        dispatch({type: GET_MONITORING_FOR_SIP, payload: null});
        dispatch({type: GET_CONSULTATION_FOR_SIP, payload: null});
        setSelectedRowData(null);
        setVisible(false);
        if (call !== null) {
            call.terminate();
            setPhoneNumber('');
            setCall(null);
            closeDetails();
            setIsCallHold(false);
            setIsCallMute(false);
            setVisible(false);
            toggleHoldMusic(false);
            setVisible(false);
        }
        setQueueId(null);
        setQueueCallId(null);
        document.title = originalTitle;
        setStatus('Call Ended');
        trackStop();

        if (endCallAudioRef) {
            const audioElement = endCallAudioRef?.current;
            if (audioElement) {
                audioElement.volume = 0.25;
                audioElement.play().catch(() => {
                    setConfirmDialogVisible(true);
                });

            }
        }
    };

    const endConsultationCall = () => {
        if (!call) {
            dispatch({type: GET_MONITORING_FOR_SIP, payload: null});
        }
        setActiveIndex(0);
        setSelectedConsultationData(null);
        dispatch({type: GET_CONSULTATION_FOR_SIP, payload: null});
        setConsultationCall(null);
        setSelectedConsultationData(null);
        setVisibleConsultation(false);
        closeConsultationDetails();
        if (consultationCall !== null) {
            consultationCall.terminate();
            setConsultationCall(null);
            setIsConsultationCallHold(false);
            setIsConsultationCallMute(false);
            setVisibleConsultation(false);
        }
        document.title = originalTitle;
        trackStop();
        setStatus('Call Ended');
        if (endCallAudioRef) {
            const audioElement = endCallAudioRef?.current;
            if (audioElement) {
                audioElement.volume = 0.25;
                audioElement.play().catch(() => {
                    setConfirmDialogVisible(true);
                });

            }
        }
    };

    const muteCall = () => {
        if (ringtoneAudioRef.current) {
            ringtoneAudioRef.current.pause();
            ringtoneAudioRef.current.currentTime = 0;
        }
    };

    const toggleHoldMusic = (isHold: boolean) => {
        if (isHold) {
            //in case of a melody for the operator while waiting
            // if (holdMusicAudioRef.current) {
            //     holdMusicAudioRef.current.play().catch(error => {
            //         console.error('Ошибка воспроизведения музыки:', error);
            //     });
            // }
        } else {
            // if (holdMusicAudioRef.current) {
            //     holdMusicAudioRef.current.pause();
            //     holdMusicAudioRef.current.currentTime = 0;
            // }
        }
    };
    const tab1HeaderTemplate = (options: { onClick: React.MouseEventHandler<HTMLDivElement> | undefined; }) => {
        let direction = incomingSession?._request?.headers?.['X-Direction']?.[0].raw || monitoringSipData?.direction;
        let callType = incomingSession?._request?.headers?.['X-Calltype']?.[0].raw || monitoringSipData?.callType;

        return (
            <div className="field col-12 mb-0 justify-content-center" style={{cursor: 'pointer'}}
                 onClick={options.onClick}>
                {<div className="field col-12 md:col-12 flex mb-0 align-items-center w-full justify-content-center">
                    <div className="mr-2">
                        {
                            callType === 'consultationExternal' ?
                                <div className="relative">
                                    <i className="pi pi-users p-2 border-circle text-white"
                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                        <div className="border-circle relative bg-primary"
                                             style={{width: '14px', height: '14px'}}>
                                            <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                        </div>
                                    </div>
                                </div> : callType === 'consultationInternal' ? <div className="relative">
                                    <i className="pi pi-users p-2 border-circle text-white"
                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                        <div className="border-circle relative bg-green-500"
                                             style={{width: '14px', height: '14px'}}>
                                            <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                        </div>
                                    </div>
                                </div> : callType === 'callbackExternal' ? (
                                    <div className="relative">
                                        <i className="pi pi-undo p-2 border-circle text-white"
                                           style={{background: 'rgb(253, 166, 16)'}}></i>
                                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                            <div className="border-circle relative bg-primary"
                                                 style={{width: '14px', height: '14px'}}>
                                                <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                            </div>
                                        </div>
                                    </div>) : callType === 'callbackInternal' ? (
                                    <div className="relative">
                                        <i className="pi pi-undo p-2 border-circle text-white"
                                           style={{background: 'rgb(253, 166, 16)'}}></i>
                                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                            <div className="border-circle relative bg-green-500"
                                                 style={{width: '14px', height: '14px'}}>
                                                <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                            </div>
                                        </div>
                                    </div>) : callType === 'transferInternal' ? (
                                    <div className="relative">
                                        <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                                           style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                                    </div>) : callType === 'transferExternal' ? (
                                    <div className="relative">
                                        <i className="pi pi-reply p-2 bg-primary border-circle text-white"
                                           style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                                    </div>) : callType === 'internal' ? <div className="relative">
                                    <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                                </div> : monitoringSipData?.direction === 'c2c' && monitoringSipData?.callType === 'callback' ?
                                    <div className="relative">
                                        <i className="pi pi-undo p-2 border-circle text-white"
                                           style={{backgroundColor: "#fda610"}}></i>
                                    </div> : monitoringSipData?.direction === 'c2c' && monitoringSipData?.callType === 'external' ?
                                        <div className="relative">
                                            <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                                        </div> :
                                        direction === 'transfer' ?
                                            <div className="relative">
                                                <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                                                   style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                                            </div> : direction !== 'transfer' ?
                                                <div className="relative"><i
                                                    className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                                                </div> : <div className="relative">
                                                    <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                                                </div>}
                    </div>
                </div>}
            </div>
        );
    };

    const consultationHeaderTemplate = (options: { onClick: React.MouseEventHandler<HTMLDivElement> | undefined; }) => {
        let callType = consultationCall?._request?.headers?.['X-Calltype']?.[0].raw;

        return (
            <div className="field col-12 mb-0 justify-content-center" style={{cursor: 'pointer'}}
                 onClick={options.onClick}>
                {<div className="field col-12 md:col-12 flex mb-0 align-items-center w-full justify-content-center">
                    <div className="mr-2">
                        {callType === 'consultationExternal' ?
                            <div className="relative">
                                <i className="pi pi-users p-2 border-circle text-white"
                                   style={{background: 'rgb(253, 166, 16)'}}></i>
                                <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                    <div className="border-circle relative bg-primary"
                                         style={{width: '14px', height: '14px'}}>
                                        <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                           style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                    </div>
                                </div>
                            </div> : callType === 'consultationInternal' ? <div className="relative">
                                    <i className="pi pi-users p-2 border-circle text-white"
                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                        <div className="border-circle relative bg-green-500"
                                             style={{width: '14px', height: '14px'}}>
                                            <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                        </div>
                                    </div>
                                </div> :
                                <div className="relative">
                                    <i className="pi pi-users p-2 border-circle text-white"
                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                        <div className="border-circle relative bg-primary"
                                             style={{width: '14px', height: '14px'}}>
                                            <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                        </div>
                                    </div>
                                </div>}
                    </div>
                </div>}
            </div>
        );
    };

    // const tab3HeaderTemplate = (options: { onClick: React.MouseEventHandler<HTMLDivElement> | undefined; }) => {
    //     return (
    //         <div className="field col-12 mb-0 justify-content-center" style={{cursor: 'pointer'}}
    //              onClick={options.onClick}>
    //             {<div className="field col-12 md:col-12 flex mb-0 align-items-center w-full justify-content-center">
    //                 <div className="mr-2">
    //                     {!incomingCall ? <div className="relative">
    //                             {callDirection === 'transfer' ?
    //                                 <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
    //                                    style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i> :
    //                                 <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>}
    //                         </div> :
    //                         <div className="relative">
    //                             <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
    //                         </div>}
    //                 </div>
    //                 <div>3</div>
    //             </div>}
    //         </div>
    //     )
    // };

    const holdCall = () => {
        if (call !== null) {
            call.hold();
            setIsCallHold(true);
            toggleHoldMusic(true);
        }
    };

    const unHoldCall = () => {
        if (call !== null) {
            call.unhold();
            setIsCallHold(false);
            toggleHoldMusic(false);
            setCallHoldDuration(0);
            if (consultationCall !== null) {
                holdConsultationCall();
            }
        }
    };

    const objectToEscapedString = (obj: { timeStart?: Date; timeEnd?: Date; call_id: any; flow_uuid: any; id: number; Sip_auth_username: null; }) => {
        const jsonString = JSON.stringify(obj, null, 2);

        return jsonString
            .replace(/"/g, '\\"')
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r');
    }

    const muteAudio = () => {
        if (call !== null) {
            call.mute({audio: true, video: false});
            setIsCallMute(true);
            const newObject = {
                ...muteCallData,
                timeStart: new Date(),
                id: muteId,
                call_id: incomingSession?._request?.headers?.['Call-ID']?.[0]?.raw || call?._request?.headers?.['Call-ID']?.[0],
                flow_uuid: flowUuid,
            };

            const escapedString = objectToEscapedString(newObject);

            dispatch(sendCallEvents('Mute', '"' + escapedString + '"', user))
        }
    };

    const unmuteAudio = () => {
        if (call !== null) {
            call.unmute({audio: true, video: false});
            setIsCallMute(false);
            setCallMuteDuration(0);
            const newObject = {
                ...unMuteCallData,
                id: muteId,
                timeEnd: new Date(),
                call_id: incomingSession?._request?.headers?.['Call-ID']?.[0]?.raw || call?._request?.headers?.['Call-ID']?.[0],
                flow_uuid: flowUuid,
            };

            const escapedString = objectToEscapedString(newObject);

            dispatch(sendCallEvents('UnMute', '"' + escapedString + '"', user))
        }
    };

    const muteConsultationAudio = () => {
        if (consultationCall !== null) {
            consultationCall.mute({audio: true, video: false});
            setIsConsultationCallMute(true);
            const newObject = {
                ...muteCallData,
                timeStart: new Date(),
                id: muteConsultationId,
                call_id: consultationCall?._request?.headers?.['Call-ID']?.[0],
                flow_uuid: flowUuid,
            };

            const escapedString = objectToEscapedString(newObject);

            dispatch(sendCallEvents('Mute', '"' + escapedString + '"', user, true))
        }
    };

    const unmuteConsultationAudio = () => {
        if (consultationCall !== null) {
            consultationCall.unmute({audio: true, video: false});
            setIsConsultationCallMute(false);
            setConsultationCallMuteDuration(0);
            const newObject = {
                ...unMuteCallData,
                id: muteConsultationId,
                timeEnd: new Date(),
                call_id: consultationCall?._request?.headers?.['Call-ID']?.[0],
                flow_uuid: flowUuid,
            };

            const escapedString = objectToEscapedString(newObject);

            dispatch(sendCallEvents('UnMute', '"' + escapedString + '"', user, true))
        }
    };

    const holdConsultationCall = () => {
        if (consultationCall !== null) {
            consultationCall.hold();
            setIsConsultationCallHold(true);
            toggleHoldMusic(true);
        }
    };

    const unHoldConsultationCall = () => {
        if (consultationCall !== null) {
            consultationCall.unhold();
            setIsConsultationCallHold(false);
            toggleHoldMusic(false);
            setConsultationCallHoldDuration(0);
            if (call !== null) {
                holdCall();
            }
        }
    };

    const showError = () => {
        // @ts-ignore
        toast?.current?.show({severity: 'error', summary: 'Поле не може бути порожнім', detail: '', life: 3000});
    }

    const showTurnDataError = () => {
        // @ts-ignore
        toast?.current?.show({severity: 'error', summary: 'Turn Data Error', detail: '', life: 3000});
    }

    const handleClickAdd = (number: string) => {
        setPhoneNumber(prevNumber => prevNumber + number);

    };
    const handleClickDelete = () => {
        setPhoneNumber(prevNumber => prevNumber.slice(0, -1));
    };

    const handleClickRemove = () => {
        if (lastCallNumber) {
            setPhoneNumber(lastCallNumber);
            makeCall();
        }
    };

    const openDetails = (id: any, isConsultation?: boolean) => {
        dispatch(getEventMonitorToUserDetail(id, jwtToken?.jwtToken, true, isConsultation));
        setVisible(true);
        setVisibleConsultation(true);
        setSelectedRowData(id);
    }

    const closeDetails = () => {
        dispatch(clearMonitoringSipDetails());
        setVisible(false);
        setSelectedRowData(null);
    }

    const closeConsultationDetails = () => {
        setVisibleConsultation(false);
        setVisible(false);
        setSelectedConsultationData(null);
    }

    const acceptCall = () => {
        setShowDialog(false);
        setCall(incomingSession);
        setIsConsultationCall(false);
        if (incomingSession) {
            incomingSession.on('failed', () => {
                setCall(null);
                closeDetails();
                setIsCallHold(false);
                setIsCallMute(false);
                setVisible(false);
                toggleHoldMusic(false);
                closeDetails();
            });

            incomingSession.on('confirmed', () => {
                setStatus('Call confirmed');
            });

            incomingSession.on('canceled', () => {
                setStatus('Call confirmed');
            });

            incomingSession.on('icecandidate', (e: any) => {
                if (e.candidate.type == "relay" && e.candidate.relatedAddress == '0.0.0.0' && e.candidate.relatedPort == 0) {
                    e.ready();
                }
            });

            incomingSession.on('accepted', () => {
                if (ringtoneAudioRef.current) {
                    ringtoneAudioRef.current.pause();
                    ringtoneAudioRef.current.currentTime = 0;
                }
                setStatus('Call Accepted');
            });

            incomingSession.on('ended', () => {
                setStatus('Call ended');
                if (ringtoneAudioRef.current) {
                    ringtoneAudioRef.current.pause();
                    ringtoneAudioRef.current.currentTime = 0;
                }
                //TEST FOR DEVICE CHANGE
                trackStop();
            });

            setLastCallNumber(incomingSession?.remote_identity.uri.user);

            let incomingSessionInfo = incomingSession?._request?.headers?.['X-Turn']?.[0]?.raw;
            let newCallOptions;

            try {
                if (incomingSessionInfo) {
                    newCallOptions = JSON.parse(incomingSessionInfo);
                } else {
                    newCallOptions = callOptions;
                }
            } catch (error) {
                newCallOptions = callOptions;
            }

            let incomingCallOptions = { ...callOptions };

            if (newCallOptions?.audio !== undefined) {
                incomingCallOptions.mediaConstraints.audio = newCallOptions.audio;
            }

            incomingSession.answer(incomingCallOptions);
            incomingSession.connection.addEventListener('addstream', (e: any) => {
                if (audioRef.current) {
                    audioRef.current.srcObject = e.stream;
                    audioRef.current.play();
                }
            });
        }
    };

    const declineCall = () => {
        if (incomingSession) {
            incomingSession.terminate();
        }
        setShowDialog(false);
    };

    const handleInputChange = (e: any) => {
        let inputValue;
        if (e?.query) {
            inputValue = e?.query;
        } else {
            inputValue = e;
        }

        setPhoneNumber(inputValue);
        if (inputValue && !webRTCError && isOnline && strengthClass !== 'weak') {
            dispatch(getUsersWebRTC(inputValue, jwtToken?.jwtToken));
        }
    }

    useEffect(() => {
        if (call) {
            if (isConsultationCall) {
                setReferCall(false);
            } else {
                setReferCall(true);
            }
        } else {
            setReferCall(false);
        }
    }, [autocompleteChange]);

    const itemTemplate = (item: any) => {
        const isUser = !item.id;
        const isInput = item?.isInput;
        const iconName = isUser && !isInput ? "pi pi-user" : isInput ? "pi pi-mobile" : "pi pi-database";
        const iconPhone = "pi pi-phone";
        const itemLabel = isUser ? item?.userName : item?.name;
        const buttonDisabled = isUser ? !item?.isLogin || !activeSession : !activeSession;
        const buttonClass = `autocomplete-item border-circle ${item?.isLogin && isUser || !isUser && activeSession ? "text-white" : "p-button-secondary bg-gray-500"} ${call && "p-button-refer"}`;
        const isRefer = !!call;
        const queueId = item?.id || null;
        return (
            <>
                <div className={`flex align-items-center justify-content-between`}>
                    <div className={`mr-${isUser ? '2' : '4'}`}><i className={`${iconName} mr-2`}></i>{itemLabel}</div>
                    {!call ? <Button
                            icon={iconPhone}
                            disabled={buttonDisabled || !isOnline}
                            className={buttonClass}
                            onClick={() => {
                                setIsConsultationCall(false);
                                setAutocompleteChange(!autocompleteChange);
                            }}
                            style={{width: '30px', height: '30px'}}
                        ></Button> :
                        <div>
                            <Button
                                icon={iconPhone}
                                disabled={buttonDisabled || !isOnline}
                                onClick={() => {
                                    setQueueCallId(queueId);
                                    setIsConsultationCall(false);
                                    setAutocompleteChange(!autocompleteChange);
                                }}
                                style={{width: '30px', height: '30px'}}
                                className={buttonClass}
                            >
                                <img className="arrow-refer" src={"assets/layout/images/arrow-right.png"} alt="avatar"/>
                            </Button>
                            <Button
                                icon={'pi pi-users'}
                                disabled={buttonDisabled || !isOnline}
                                onClick={() => {
                                    setQueueCallId(queueId);
                                    setIsConsultationCall(true);
                                    setAutocompleteChange(!autocompleteChange);
                                }}
                                style={{width: '30px', height: '30px', background: 'rgb(253, 166, 16)'}}
                                className={`autocomplete-item border-circle ${item?.isLogin && isUser || !isUser && activeSession ? "text-white" : "p-button-secondary bg-gray-500"} ml-2 border-none`}
                            >
                            </Button>
                        </div>
                    }
                </div>
            </>
        );
    }

    const accept = () => {
        if (incomingSession) {
            ringtoneAudioRef?.current?.play();
        }
    }

    const handleReferCall = (value: any, queueId: number | null) => {
        let targetValue = queueId ? queueId : value;
        const targetUri = `sip:${targetValue}@${WWS_WEBRTC}`;
        if (call) {
            const extraHeaders: any = [`X-flow_uuid: ${flowUuid}`];

            if (groupFlowUuid) {
                extraHeaders.push(`X-group_flow_uuid: ${groupFlowUuid}`);
                dispatch(clearRecallSession());
                setGroupFlowUuid('');
            }

            const referSubscriber = call.refer(targetUri, {
                extraHeaders: extraHeaders
            });

            if (referSubscriber) {
                referSubscriber.on('requestSucceeded', () => {
                    endCall();
                });

                referSubscriber.on('requestFailed', (e: any) => {
                    console.log('REFER не удался:', e);
                });
            }
        }
    };

    const handleReferToConsultationCall = (value: any) => {
        const targetUri = `sip:${value}@${WWS_WEBRTC}`;
        if (call) {
            const extraHeaders: any = [`X-flow_uuid: ${flowUuid}`];
            extraHeaders.push(`X-campaign_id: transfer::0`);

            if (groupFlowUuid) {
                extraHeaders.push(`X-group_flow_uuid: ${groupFlowUuid}`);
                dispatch(clearRecallSession());
                setGroupFlowUuid('');
            }

            const referSubscriber = call.refer(targetUri, {
                extraHeaders: extraHeaders
            });

            if (referSubscriber) {
                referSubscriber.on('requestSucceeded', () => {
                    endCall();
                    endConsultationCall();
                    setConsultationReferCall(false);
                });

                referSubscriber.on('requestFailed', (e: any) => {
                    console.log('REFER не удался:', e);
                    setConsultationReferCall(false);
                });
            }
        }
    };

    const normalizePhone = (phone: string | any) => {
        if (phone.startsWith('38')) {
            return phone.substring(2);
        }
        return phone;
    }

    const extractUsername = (sipString: any): string => {
        const match = sipString?.match(/<sip:([^@]+)@/);
        return match ? match[1] : '';
    };

    return (
        <div
            className={`flex flex-wrap justify-content-center ${DEV ? 'jssip-dev' : STAGE ? 'jssip-stage' : ''} jssip-tabview`}>
            {(!isOnline || strengthClass === 'weak') && <div className="layout-inline-menu m-0 mb-4 w-full">
                <ul className="layout-inline-menu-action-panel m-0">
                    <li className="layout-inline-menu-action-item m-0">
                        <button className="p-link">
                            <i style={{color: "red"}} className="pi pi-info-circle pi-fw"></i>
                            <span className="text-lg">Нестабільне інтернет з'єднання.</span>
                        </button>
                    </li>
                </ul>
            </div>}
            {errorJsSip && <div className="layout-inline-menu m-0 mb-4 w-full">
                <ul className="layout-inline-menu-action-panel m-0">
                    <li className="layout-inline-menu-action-item m-0">
                        <button className="p-link">
                            <i style={{color: "red"}} className="pi pi-info-circle pi-fw"></i>
                            <span className="text-lg">SIP підключення не активно</span>
                        </button>
                    </li>
                </ul>
            </div>}
            {monitoringHubError && hash === '#/monitoring' &&
                <div className="layout-inline-menu m-0 mb-4 w-full">
                    <ul className="layout-inline-menu-action-panel m-0">
                        <li className="layout-inline-menu-action-item m-0">
                            <button className="p-link">
                                <i style={{color: "red"}} className="pi pi-info-circle pi-chart-bar"></i>
                                <span className="text-lg">Помилка підключення monitoringHub.</span>
                            </button>
                        </li>
                    </ul>
                </div>}
            {statusHubError && <div className="layout-inline-menu m-0 mb-4 w-full">
                <ul className="layout-inline-menu-action-panel m-0">
                    <li className="layout-inline-menu-action-item m-0">
                        <button className="p-link">
                            <i style={{color: "red"}} className="pi pi-info-circle pi-fw"></i>
                            <span className="text-lg">Помилка підключення statusHub.</span>
                        </button>
                    </li>
                </ul>
            </div>}
            {microphoneAccess !== 'granted' && <div className="layout-inline-menu m-0 mb-4 w-full">
                <ul className="layout-inline-menu-action-panel m-0">
                    <li className="layout-inline-menu-action-item m-0">
                        <button className="p-link">
                            <i style={{color: "red"}} className="pi pi-info-circle pi-fw"></i>
                            <span className="text-lg">Дзвінки недоступні.
Відсутній доступ до мікрофона.</span>
                        </button>
                    </li>
                </ul>
            </div>}
            <Toast position="bottom-right" ref={toast}/>
            <Toast position="bottom-right" ref={toastError}/>
            <audio ref={ringtoneAudioRef} loop src={'assets/layout/audio/ringtone.mp3'}/>
            {/*<audio ref={holdMusicAudioRef} loop src={'assets/layout/audio/onHold.mp3'}/>*/}
            <audio ref={audioRef} autoPlay={true}></audio>
            <audio ref={endCallAudioRef} src={'assets/layout/audio/endCall.mp3'}/>
            <ConfirmDialog visible={confirmDialogVisible} onHide={() => setConfirmDialogVisible(false)}
                           message="Увімкнути автоматичне відтворення звуку під час дзвінка ?"
                           header="" acceptLabel="Так" rejectLabel="Запитати пізніше" icon="pi pi-exclamation-triangle"
                           accept={accept}/>
            <div className="grid p-fluid max-w-22rem card">
                <div className="flex w-full align-items-center field mx-3">
                    <div className="flex align-items-center justify-content-start"
                         style={{minWidth: `25px`, minHeight: '25px'}}>
                        {volume === 0 && (
                            <i className="pi pi-volume-off" style={{fontSize: '18px', width: `25px`}}></i>
                        )}
                        {volume > 0 && volume <= 0.5 && (
                            <i className="pi pi-volume-down" style={{fontSize: '18px', width: `25px`}}></i>
                        )}
                        {volume > 0.5 && (
                            <i className="pi pi-volume-up" style={{fontSize: '1.3rem'}}></i>
                        )}
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        className="slider w-full bg-cyan-600"
                        value={volume}
                        onChange={(e) => {
                            setVolume(parseFloat(e.target.value));
                            if (audioRef.current) {
                                audioRef.current.volume = parseFloat(e.target.value);
                            }
                            if (holdMusicAudioRef.current) {
                                holdMusicAudioRef.current.volume = parseFloat(e.target.value);
                            }
                            if (ringtoneAudioRef.current) {
                                ringtoneAudioRef.current.volume = parseFloat(e.target.value);
                            }
                        }}
                    />
                </div>
                <div className="field col-12 md:col-12 flex align-items-center mb-1">
                    <button onClick={() => setShowKeyboard(prevKeyboard => !prevKeyboard)}
                            className="p-button-secondary border-none h-full cursor-pointer flex align-items-center justify-content-center mr-2"
                            style={{backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '6px'}}>
                        <img style={{height: '20px'}} alt='pnone-btns' src={'assets/layout/images/moby.png'}/>
                    </button>
                    <AutoComplete
                        value={phoneNumber}
                        itemTemplate={itemTemplate}
                        className="w-full"
                        placeholder="Введіть номер або userName"
                        panelClassName="statusNamePanel"
                        disabled={!isOnline || errorJsSip || closeCallButton}
                        suggestions={updatedResults}
                        completeMethod={handleInputChange}
                        onChange={(e) => {
                            if (e.value && e.value.userName) {
                                setPhoneNumber(e.value.userName);
                                checkUser(e.value.userName, !!call, null);
                                setQueueCallId(null);
                            } else if (e.value && e.value.name) {
                                setPhoneNumber(e.value.name);
                                setQueueCallId(e.value?.id);
                                checkUser(e.value.name, !!call, e.value?.id);
                            } else {
                                setPhoneNumber(e.value);
                            }
                        }}
                    />
                </div>
                {/* @ts-ignore: Ignoring TypeScript error for CSSTransition */}
                <CSSTransition
                    nodeRef={keyboardRef as React.RefObject<HTMLDivElement>}
                    in={showKeyboard}
                    timeout={1}
                    classNames="keyboard"
                    unmountOnExit
                >
                    <div ref={keyboardRef} className="field col-12 md:col-12 flex align-items-center mb-0">
                        <PhoneKeyboard
                            handleClickAdd={handleClickAdd}
                            handleClickDelete={handleClickDelete}
                            handleClickRemove={handleClickRemove}
                        />
                    </div>
                </CSSTransition>
                <div className="field col-12 md:col-12 flex justify-content-between flex flex-wrap mb-0">
                    {<Button label="Виклик"
                             disabled={errorJsSip || !activeSession || strengthClass === 'signal-icon-offline' || !isOnline || errorJsSip || closeCallButton || (call || incomingSession || consultationCall)}
                             className="w-full font-bold bg-cyan-600 border-cyan-600"
                             onClick={() => checkUser(phoneNumber, false, queueCallId)}></Button>}
                </div>
                {(call || consultationCall) &&
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}
                             className="w-full mb-2 mt-2">
                        {call && <TabPanel header="" className="w-full pt-0"
                                           headerTemplate={tab1HeaderTemplate}
                        >
                            {call && <div className="field col-12 md:col-12 flex mb-0 align-items-center w-full">
                                <div className="flex justify-content-between w-full flex align-items-center">
                                    <div className="flex align-items-center">
                                        <p className="font-bold mb-0">{monitoringSipData?.direction || callDirection}</p>
                                    </div>
                                </div>
                                {!!incomingSession?._request?.headers?.['X-Queueid']?.[0]?.raw &&
                                    <div className="ml-auto">
                                        <p className="font-bold mb-0 flex"><span
                                            className="block">ID:</span> {incomingSession?._request?.headers?.['X-Queueid']?.[0]?.raw}
                                        </p>
                                    </div>}
                                {!!incomingSession?._request?.headers?.['X-Campaignid']?.[0]?.raw && incomingSession?._request?.headers?.['X-Direction']?.[0]?.raw !== "c2c" && incomingSession?._request?.headers?.['X-Direction']?.[0].raw !== 'transfer' &&
                                    <div className="ml-auto">
                                        <p className="font-bold mb-0 flex"><span
                                            className="block">ID:</span> {incomingSession?._request?.headers?.['X-Campaignid']?.[0]?.raw}
                                        </p>
                                    </div>}
                            </div>}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {!!incomingSession?._request?.from?._display_name && callDirection !== 'transfer' && (normalizePhone(incomingSession?.remote_identity?.uri?.user) !== normalizePhone(incomingSession?._request?.from?._display_name)) &&
                                <div className="field col-12 md:col-12 flex mb-0 align-items-center">
                                    <p className="font-bold mb-0">{incomingSession?._request?.from?._display_name}</p>
                                </div>}
                            {!!incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw && callDirection === 'transfer' && (normalizePhone(incomingSession?.remote_identity?.uri?.user) !== normalizePhone(extractUsername(incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw))) &&
                                <div className="field col-12 md:col-12 flex mb-0 align-items-center">
                                    <p className="font-bold mb-0">{extractUsername(incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw)}</p>
                                </div>
                            }

                            {!!incomingSession?._request?.from?._display_name && callDirection !== 'transfer' && (normalizePhone(incomingSession?.remote_identity?.uri?.user) !== normalizePhone(incomingSession?._request?.from?._display_name)) &&
                                <div className="field col-12 md:col-12 p-0"
                                     style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>}
                            {!!incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw && callDirection === 'transfer' && (normalizePhone(incomingSession?.remote_identity?.uri?.user) !== normalizePhone(extractUsername(incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw))) &&
                                <div className="field col-12 md:col-12 p-0"
                                     style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>}
                            {call && <div className="field col-12 md:col-12 flex mb-0 align-items-center">
                                <p className="font-bold mb-0">{incomingSession?.remote_identity.uri.user || call?.remote_identity.uri.user}</p>
                            </div>}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {call &&
                                <div className="col-12 md:col-12 flex justify-content-between mb-0 align-items-center">
                                    <div className="justify-content-between align-items-center">
                                        <Button icon="pi pi-clock"
                                                className="p-button-secondary flex align-items-center justify-content-center"
                                                style={{minWidth: '30px', minHeight: '30px', width: `30px`}}></Button>
                                    </div>
                                    <div style={{minWidth: '63px'}}>{formatCallDuration(callDuration)}</div>
                                </div>}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {!isCallHold ? (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 pb-2 mb-0">
                                    <Button
                                        className="p-button-secondary flex align-items-center justify-content-center"
                                        style={{minWidth: '30px', minHeight: '30px', width: `30px`}} onClick={holdCall}>
                                        <img style={{height: '15px'}} alt='pnone-btns1'
                                             src={'assets/layout/images/telephone-call.png'}/>
                                    </Button>
                                    <div style={{minWidth: '63px'}}>{formatCallDuration(callHoldDuration)}</div>
                                </div>) : (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 mb-0 pb-2">
                                    <Button
                                        className="p-button-secondary flex align-items-center justify-content-center"
                                        style={{minWidth: '30px', minHeight: '30px', width: `30px`}}
                                        onClick={unHoldCall}>
                                        <img style={{height: '15px'}} alt='pnone-btns1'
                                             src={'assets/layout/images/call-red.png'}/>
                                    </Button>
                                    <div
                                        style={{color: 'red', minWidth: '63px'}}>{formatCallDuration(callHoldDuration)}</div>
                                </div>
                            )}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {!isCallMute ? (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 pb-2 mb-0">
                                    <Button icon="pi pi-microphone"
                                            className="p-button-secondary flex align-items-center justify-content-center"
                                            style={{minWidth: '30px', minHeight: '30px', width: `30px`}}
                                            onClick={muteAudio}>
                                    </Button>
                                    <div style={{minWidth: '63px'}}>{formatCallDuration(callMuteDuration)}</div>
                                </div>) : (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 mb-0 pb-2">
                                    <Button icon="pi pi-microphone"
                                            className="p-button-secondary flex align-items-center justify-content-center"
                                            style={{minWidth: '30px', minHeight: '30px', width: `30px`, color: 'red'}}
                                            onClick={unmuteAudio}>
                                    </Button>
                                    <div
                                        style={{color: 'red', minWidth: '63px'}}>{formatCallDuration(callMuteDuration)}</div>
                                </div>
                            )}
                            <div className="field col-12 md:col-12 p-0 mb-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            <div
                                className="field col-12 md:col-12 flex justify-content-between incomingDialog flex flex-wrap px-0 mr-1 mb-0">
                                <div className="field col-12 md:col-12 pl-0 mb-0 flex justify-content-between">
                                    {(incomingSession?._request?.headers?.['X-Calldetailid']?.[0]?.raw || monitoringSipData?.id || monitoringSipData?.callDetailId) &&
                                        <Button
                                            className="font-bold button-primary justify-content-center decline-call w-full mb-0 decline-call border-round-xl max-w-5rem"
                                            onClick={() => openDetails((incomingSession?._request?.headers?.['X-Calldetailid']?.[0]?.raw || monitoringSipData?.id || monitoringSipData?.callDetailId))}
                                            style={{minWidth: '60px', minHeight: '40px'}}><i
                                            className="pi pi-info-circle"
                                            style={{fontSize: '1.3rem'}}></i></Button>}
                                    {/*<Button icon="pi pi-arrow-right-arrow-left"*/}
                                    {/*        className="font-bold text-xl border-none shadow-none justify-content-center w-full border-round-xl decline-call mb-0 max-w-5rem"*/}
                                    {/*        style={{minWidth: '60px', minHeight: '40px', backgroundColor: '#873efe'}}></Button>*/}
                                    {consultationCall && <Button
                                        onClick={() => {
                                            handleReferToConsultationCall(consultationSipData?.aNI || consultationSipData?.ani || consultationCall?.remote_identity.uri.user);
                                        }}
                                        className={`font-bold justify-content-center w-full mb-0 shadow-none outline-none border-round-xl border-none ${(incomingSession?._request?.headers?.['X-Calldetailid']?.[0]?.raw || monitoringSipData?.id || monitoringSipData?.callDetailId) ? 'max-w-5rem' : 'max-w-full mr-1'}`}
                                        icon={'pi pi-phone'}
                                        style={{backgroundColor: 'rgb(127, 72, 255)'}}><img className="arrow-refer"
                                                                                            style={{
                                                                                                width: '13px',
                                                                                                left: '56%',
                                                                                                transform: 'translate(-55%, 0)',
                                                                                                top: '9px'
                                                                                            }}
                                                                                            src={"assets/layout/images/arrow-right.png"}
                                                                                            alt="avatar"/>
                                    </Button>}
                                    <Button
                                        className={`font-bold p-button-danger justify-content-center w-full border-round-xl decline-call mb-0 ${(incomingSession?._request?.headers?.['X-Calldetailid']?.[0]?.raw || monitoringSipData?.id || monitoringSipData?.callDetailId) ? 'max-w-5rem' : consultationCall ? 'max-w-full ml-1' : 'max-w-full'}`}
                                        onClick={endCall} style={{minWidth: '60px', minHeight: '40px'}}><i
                                        className="pi pi-phone" style={{fontSize: '1.3rem'}}></i>
                                    </Button>
                                </div>
                                {/*<div className="field col-4 md:col-4 px-1 mb-0">*/}
                                {/*</div>*/}
                                {/*<div className="field col-4 md:col-4 pr-0 mb-0">*/}
                                {/*    <Button*/}
                                {/*        className="font-bold p-button-danger justify-content-center w-full border-round-xl decline-call mb-0"*/}
                                {/*        onClick={endCall} style={{minWidth: '60px', minHeight: '40px'}}><i className="pi pi-phone" style={{fontSize: '1.3rem'}}></i>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                            </div>
                        </TabPanel>}
                        {consultationCall && <TabPanel header="" className="w-full pt-0"
                                                       headerTemplate={consultationHeaderTemplate}
                        >
                            {consultationCall &&
                                <div className="field col-12 md:col-12 flex mb-0 align-items-center w-full">
                                    <div className="flex justify-content-between w-full flex align-items-center">
                                        <div className="flex align-items-center">
                                            <p className="font-bold mb-0">{consultationCall?._request?.headers?.['X-Direction']?.[0]?.raw || consultationSipData?.direction || '- - -'}</p>
                                        </div>
                                    </div>
                                </div>}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {consultationCall && <div className="field col-12 md:col-12 flex mb-0 align-items-center">
                                <p className="font-bold mb-0">{consultationCall?.remote_identity.uri.user}</p>
                            </div>}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {consultationCall &&
                                <div className="col-12 md:col-12 flex justify-content-between mb-0 align-items-center">
                                    <div className="justify-content-between align-items-center">
                                        <Button icon="pi pi-clock"
                                                className="p-button-secondary flex align-items-center justify-content-center"
                                                style={{minWidth: '30px', minHeight: '30px', width: `30px`}}></Button>
                                    </div>
                                    <div style={{minWidth: '63px'}}>{formatCallDuration(consultationCallDuration)}</div>
                                </div>}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {!isConsultationCallHold ? (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 pb-2 mb-0">
                                    <Button
                                        className="p-button-secondary flex align-items-center justify-content-center"
                                        style={{minWidth: '30px', minHeight: '30px', width: `30px`}}
                                        onClick={holdConsultationCall}>
                                        <img style={{height: '15px'}} alt='pnone-btns1'
                                             src={'assets/layout/images/telephone-call.png'}/>
                                    </Button>
                                    <div
                                        style={{minWidth: '63px'}}>{formatCallDuration(consultationCallHoldDuration)}</div>
                                </div>) : (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 mb-0 pb-2">
                                    <Button
                                        className="p-button-secondary flex align-items-center justify-content-center"
                                        style={{minWidth: '30px', minHeight: '30px', width: `30px`}}
                                        onClick={unHoldConsultationCall}>
                                        <img style={{height: '15px'}} alt='pnone-btns1'
                                             src={'assets/layout/images/call-red.png'}/>
                                    </Button>
                                    <div
                                        style={{color: 'red', minWidth: '63px'}}>{formatCallDuration(consultationCallHoldDuration)}</div>
                                </div>
                            )}
                            <div className="field col-12 md:col-12 p-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            {!isConsultationCallMute ? (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 pb-2 mb-0">
                                    <Button icon="pi pi-microphone"
                                            className="p-button-secondary flex align-items-center justify-content-center"
                                            style={{minWidth: '30px', minHeight: '30px', width: `30px`}}
                                            onClick={muteConsultationAudio}>
                                    </Button>
                                    <div
                                        style={{minWidth: '63px'}}>{formatCallDuration(consultationCallMuteDuration)}</div>
                                </div>) : (
                                <div
                                    className="flex field justify-content-between align-items-center pl-2 pr-2 pt-2 mb-0 pb-2">
                                    <Button icon="pi pi-microphone"
                                            className="p-button-secondary flex align-items-center justify-content-center"
                                            style={{minWidth: '30px', minHeight: '30px', width: `30px`, color: 'red'}}
                                            onClick={unmuteConsultationAudio}>
                                    </Button>
                                    <div
                                        style={{color: 'red', minWidth: '63px'}}>{formatCallDuration(consultationCallMuteDuration)}</div>
                                </div>
                            )}
                            <div className="field col-12 md:col-12 p-0 mb-0"
                                 style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                            <div
                                className="field col-12 md:col-12 flex justify-content-between incomingDialog flex flex-wrap px-0 mr-1 mb-0">
                                <div className="field col-12 md:col-12 pl-0 mb-0 flex justify-content-between">
                                    {(consultationSipData?.id || consultationSipData?.callDetailId) &&
                                        <Button
                                            className="font-bold button-primary justify-content-center decline-call w-full mb-0 decline-call border-round-xl max-w-5rem"
                                            onClick={() => openDetails((consultationSipData?.id || consultationSipData?.callDetailId), true)}
                                            style={{minWidth: '60px', minHeight: '40px'}}><i
                                            className="pi pi-info-circle"
                                            style={{fontSize: '1.3rem'}}></i></Button>}
                                    {consultationCall && <Button
                                        onClick={() => {
                                            handleReferToConsultationCall(consultationSipData?.aNI || consultationSipData?.ani || consultationCall?.remote_identity.uri.user);
                                        }}
                                        className={`font-bold justify-content-center w-full mb-0 shadow-none outline-none border-round-xl border-none ${(consultationSipData?._request?.headers?.['X-Calldetailid']?.[0]?.raw || consultationSipData?.id || consultationSipData?.callDetailId) ? 'max-w-5rem' : 'max-w-full mr-1'}`}
                                        icon={'pi pi-phone'}
                                        style={{backgroundColor: 'rgb(127, 72, 255)'}}><img className="arrow-refer"
                                                                                            style={{
                                                                                                width: '13px',
                                                                                                left: '56%',
                                                                                                transform: 'translate(-55%, 0)',
                                                                                                top: '9px'
                                                                                            }}
                                                                                            src={"assets/layout/images/arrow-right.png"}
                                                                                            alt="avatar"/>
                                    </Button>}
                                    <Button
                                        className={`font-bold p-button-danger justify-content-center w-full border-round-xl decline-call mb-0 ${(consultationSipData?.id || consultationSipData?.callDetailId) ? 'max-w-5rem' : 'max-w-full'}`}
                                        onClick={endConsultationCall} style={{minWidth: '60px', minHeight: '40px'}}><i
                                        className="pi pi-phone" style={{fontSize: '1.3rem'}}></i>
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>}
                    </TabView>}

                {showDialog && incomingSession && <div className="flex field col-12 p-fluid max-w-22rem mb-2 px-0">
                    <div className="field col-12 md:col-12 flex flex-column align-items-center mb-1 card">
                        <p className="font-bold text-2xl text-green-500 mb-1">Вхідний дзвінок</p>
                        <div className="flex align-items-center w-full mb-2">
                            <div className="flex align-items-center justify-content-around w-full">
                                <div className="flex align-items-center">
                                    <div className="relative">
                                        {incomingSession?._request?.headers?.['X-Direction']?.[0].raw === 'transfer' ?
                                            <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                                               style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i> :
                                            incomingSession?._request?.headers?.['X-Calltype']?.[0].raw === 'consultationInternal' ? (
                                                <div className="relative">
                                                    <i className="pi pi-users p-2 border-circle text-white"
                                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                        <div className="border-circle relative bg-green-500"
                                                             style={{width: '14px', height: '14px'}}>
                                                            <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : incomingSession?._request?.headers?.['X-Calltype']?.[0].raw === 'callbackExternal' ? (
                                                <div className="relative">
                                                    <i className="pi pi-undo p-2 border-circle text-white"
                                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                        <div className="border-circle relative bg-primary"
                                                             style={{width: '14px', height: '14px'}}>
                                                            <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                        </div>
                                                    </div>
                                                </div>) : incomingSession?._request?.headers?.['X-Calltype']?.[0].raw === 'callbackInternal' ? (
                                                <div className="relative">
                                                    <i className="pi pi-undo p-2 border-circle text-white"
                                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                        <div className="border-circle relative bg-green-500"
                                                             style={{width: '14px', height: '14px'}}>
                                                            <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                        </div>
                                                    </div>
                                                </div>) : incomingSession?._request?.headers?.['X-Calltype']?.[0].raw === 'consultationExternal' ? (
                                                <div className="relative">
                                                    <i className="pi pi-users p-2 border-circle text-white"
                                                       style={{background: 'rgb(253, 166, 16)'}}></i>
                                                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                                                        <div className="border-circle relative bg-primary"
                                                             style={{width: '14px', height: '14px'}}>
                                                            <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : incomingSession?._request?.headers?.['X-Calltype']?.[0].raw === "transferInternal" ? (
                                                <div className="relative">
                                                    <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                                                       style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                                                </div>
                                            ) : incomingSession?._request?.headers?.['X-Calltype']?.[0].raw === 'external' ? (
                                                    <div className="relative">
                                                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                                                    </div>
                                                ) :
                                                <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>}
                                    </div>
                                    <p className="font-bold mb-0 ml-2">{callDirection}</p>
                                </div>
                                {!!incomingSession?._request?.headers?.['X-Queueid']?.[0]?.raw &&
                                    <div className="ml-auto">
                                        <p className="font-bold mb-0 flex"><span
                                            className="block">ID:</span> {incomingSession?._request?.headers?.['X-Queueid']?.[0]?.raw}
                                        </p>
                                    </div>}
                                {!!incomingSession?._request?.headers?.['X-Campaignid']?.[0]?.raw && incomingSession?._request?.headers?.['X-Direction']?.[0].raw !== 'transfer' &&
                                    <div className="ml-auto">
                                        <p className="font-bold mb-0 flex"><span
                                            className="block">ID:</span> {incomingSession?._request?.headers?.['X-Campaignid']?.[0]?.raw}
                                        </p>
                                    </div>}
                            </div>
                        </div>
                        {!!incomingSession?._request?.from?._display_name && callDirection !== 'transfer' && (normalizePhone(incomingSession?.remote_identity?.uri?.user) !== normalizePhone(incomingSession?._request?.from?._display_name)) &&
                            <div>
                                <p className="font-bold text-xl">{incomingSession?._request?.from?._display_name}</p>
                            </div>}
                        {!!incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw && callDirection === 'transfer' && (normalizePhone(incomingSession?.remote_identity?.uri?.user) !== normalizePhone(extractUsername(incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw))) &&
                            <div>
                                <p className="font-bold text-xl">{extractUsername(incomingSession?._request?.headers?.['Referred-By']?.[0]?.raw)}</p>
                            </div>}
                        <p className="font-bold text-xl mb-0 text-center">{incomingSession?.remote_identity?.uri?.user}</p>

                        <div className="field col-12 md:col-12 p-0 my-3"
                             style={{margin: 0, borderBottom: '1px solid #cccccc38'}}></div>
                        <div className="incomingDialog flex justify-content-between">
                            {(incomingSession?._request?.headers?.['X-Calldetailid']?.[0]?.raw || monitoringSipData?.id || monitoringSipData?.callDetailId) &&
                                <Button
                                    className="font-bold button-primary justify-content-center decline-call w-full mb-0 decline-call border-round-2xl max-w-5rem"
                                    onClick={() => openDetails((incomingSession?._request?.headers?.['X-Calldetailid']?.[0]?.raw || monitoringSipData?.id || monitoringSipData?.callDetailId))}
                                    style={{minWidth: '60px', minHeight: '40px'}}><i className="pi pi-info-circle"
                                                                                     style={{fontSize: '1.3rem'}}></i></Button>}
                            <Button icon="pi pi-phone"
                                    className="font-bold justify-content-center border-round-2xl mx-4 answer-call shadow-none"
                                    onClick={acceptCall}
                                    style={{minWidth: '60px', minHeight: '40px', backgroundColor: '#369b26', borderColor: '#369b26'}}></Button>
                            <Button icon="pi pi-phone"
                                    className="font-bold p-button-danger justify-content-center border-round-2xl decline-call"
                                    onClick={declineCall} style={{minWidth: '60px', minHeight: '40px'}}></Button>
                            {/*<Button*/}
                            {/*    className="font-bold p-button-danger bg-orange-400 border-orange-400 justify-content-center border-round-2xl mute-call"*/}
                            {/*    style={{minWidth: '60px', minHeight: '40px', maxWidth: '40px'}} onClick={muteCall}>*/}
                            {/*    <img style={{width: '17px'}} alt='Mute icons created by Google - Flaticon'*/}
                            {/*         src={'assets/layout/images/volume-off.png'}/>*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                </div>}
                {selectedRowData && (monitoringSipData?.callDetailId || monitoringSipData?.id) && visible && activeIndex === 0 && (call || incomingSession || userStatus?.statusesId === 11) &&
                    <MonitoringSipDetails setVisible={closeDetailVisible}/>
                }
                {selectedConsultationData && (consultationSipData?.callDetailId || consultationSipData?.id) && visibleConsultation && activeIndex === 1 && consultationCall &&
                    <ConsultationSipDetails setVisible={closeConsultationDetails}/>
                }
            </div>
        </div>
    );
}


export default SIPCallComponent;
