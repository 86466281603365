import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column, ColumnFilterElementTemplateOptions} from 'primereact/column';
import {classNames} from "primereact/utils";
import {Badge} from "primereact/badge";
import {Button} from "primereact/button";
import 'react-h5-audio-player/lib/styles.css';
import {Dialog} from "primereact/dialog";
import MonitoringListNav from "../MonitoringListNav";
import {
    getCallResetType, getCallTypeDictionary,
    getCampaignFilterList, getDirectionDictionary,
    getEventMonitorToUserDetail,
    getForcedClosingType,
    getMonitoringWithPaginationFilterAndSorting, getOwnerTypeDictionary,
    getStatusDirectoriesStatusType,
    getUserSortFields
} from "../../../redux/api/apiMonitoringList";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {Paginator} from "primereact/paginator";
import {
    getAudioUrlForPlayer,
    setCurrentPage,
    setCurrentPageSize,
    setMonitoringFilter
} from "../../../redux/actions/actionsMonitoringList";
import {MultiSelect} from "primereact/multiselect";
import {Calendar} from "primereact/calendar";
import {UPLOAD_AUDIO_LOADING} from "../../../redux/types/types";
import {getQueueDirectoriesQueueDirectionsType} from "../../../redux/api/apiQueue";
import {DataRow, DecodedToken} from "../../../types/types";
import {getAllSimpleUsers, getUsers} from "../../../redux/api/api";
import MonitoringListDetails from "../MonitoringList/MonitoringListDetails";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {WSS_URL} from "../../../redux/config";
import * as signalR from "@microsoft/signalr";
import {setMonitoringHubError} from "../../../redux/actions/actionsWebRTC";
import {GET_MONITORING, GET_MONITORINGS} from "../../../redux/types/typesMonitoringList";
import {jwtDecode} from "jwt-decode";

interface NetworkInformation extends EventTarget {
    downlink: number;
    effectiveType: string;
}
const MonitoringBranches = (props: any) => {
    const dispatch = useAppDispatch();
    const monitoringList = useAppSelector(state => state.MonitoringListReducer?.monitoringList);
    const userSortFields: any = useAppSelector(state => state.RolesReducer.userSortFields);
    const campaignList = useAppSelector(state => state.MonitoringListReducer?.campaignListName);
    const monitoringData = useAppSelector(state => state.MonitoringListReducer?.monitoringData);
    const paginationSize = useAppSelector(state => state.MonitoringListReducer.paginationSize);
    const pagination = useAppSelector(state => state.MonitoringListReducer.pagination);
    const loader = useAppSelector(state => state.MonitoringListReducer.loading);
    const paginationPage = useAppSelector(state => state.MonitoringListReducer.paginationPage);
    const statusDictionary = useAppSelector(state => state.MonitoringListReducer.statusDictionary);
    const callResetDictionary = useAppSelector(state => state.MonitoringListReducer.callResetDictionary);
    const forcedClosingDictionary = useAppSelector(state => state.MonitoringListReducer.forcedClosingDictionary);
    const ownerTypeDictionary = useAppSelector(state => state.MonitoringListReducer.ownerTypeDictionary);
    const callTypeDictionary = useAppSelector(state => state.MonitoringListReducer.callTypeDictionary);
    const clearFilters = useAppSelector(state => state.MonitoringListReducer.clearFilters)
    const user = useAppSelector(state => state.UsersReducer.userData);
    // @ts-ignore
    const activeClick = useAppSelector(state => state.UsersListReducer.activeClick);
    const [first, setFirst] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [selectedRowExpansion, setSelectedRowExpansion] = useState<any | null>(null);
    const [selectedSessionRowExpansion] = useState<any | null>(null);
    const [selectedBSessionRowExpansion] = useState<any | null>(null);
    const [orderBy, setOrderBy] = useState<any>(null);
    const [isDescFilter, setIsDescFilter] = useState<boolean>(true);
    const [sortOrder, setSortOrder] = useState<number>(0);
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [startFinishTime, setStartFinishTime] = useState<any>(null);
    const [endFinishTime, setEndFinishTime] = useState<any>(null);
    const [filterCampaignId, setFilterCampaignId] = useState<any>(null);
    const [filterNameTo, setFilterNameTo] = useState<any>(null);
    const [filterNameFrom, setFilterNameFrom] = useState<any>(null);
    const [filterAmdHangup, setFilterAmdHangup] = useState<any>(null);
    const [filterCallReset, setFilterCallReset] = useState<any>(null);
    const [filterStatus, setFilterStatus] = useState<any>(null);
    const [filterCampaignName, setFilterCampaignName] = useState<any>(null);
    const [filterQueueName, setFilterQueueName] = useState<any>(null);
    const [filterFullName, setFilterFullName] = useState<any>(null);
    const [filterTypeChannel, setFilterTypeChannel] = useState<any>(null);
    const [filterOwnerType, setFilterOwnerType] = useState<any>(null);
    const [filterDirection, setFilterDirection] = useState<any>(null);
    const [filterDateTime, setFilterDateTime] = useState<any>(false);
    const [filterEndDateTime, setFilterEndDateTime] = useState<any>(false);
    const [filterFinishDateTime, setFilterFinishDateTime] = useState<any>(false);
    const [filterFinishEndDateTime, setFilterFinishEndDateTime] = useState<any>(false);
    const [fullCallDurationStartTime, setfullCallDurationStartTime] = useState<any>(false);
    const [fullCallDurationEndTime, setfullCallDurationEndTime] = useState<any>(false);
    const [durationStartTime, setDurationStartTime] = useState<any>(false);
    const [durationEndTime, setDurationEndTime] = useState<any>(false);
    const [reactionClientStartTime, setReactionClientStartTime] = useState<any>(false);
    const [reactionClientEndTime, setReactionClientEndTime] = useState<any>(false);
    const [reactionOperatorStartTime, setReactionOperatorStartTime] = useState<any>(false);
    const [reactionOperatorEndTime, setReactionOperatorEndTime] = useState<any>(false);
    const [visible, setVisible] = useState(false);
    const [filterCallOwner, setFilterCallOwner] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>('table1');
    const dataTableRef = useRef<DataTable | any>(null);
    // @ts-ignore
    const queuesDirectoryDirectionType = useAppSelector(state => state.QueueListReducer.queuesDirectoryDirectionType) as any;
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [expandedSessionRows, setExpandedSessionRows] = useState<any>([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [key, setKey] = useState(1);
    const jwtToken = useAppSelector(state => state.UsersReducer.userData.jwtToken);
    // @ts-ignore
    const usersData = useAppSelector(state => state.CompaniesListReducer.allSimpleUsers);
    const [selectedColumns, setSelectedColumns] = useState<any>(userSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    const pageChanged = useAppSelector(state => state.MonitoringListReducer.pageChanged);
    const [fullCallDuration, setFullCallDuration] = useState<boolean>(false);
    const [duration, setDuration] = useState<boolean>(false);
    const [reactionClient, setReactionClient] = useState<boolean>(false);
    const [reactionOperator, setReactionOperator] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(paginationSize);
    const [consultationFlowUuid, setConsultationFlowUuid] = useState('');
    const monitoringFilterData = useAppSelector(state => state.MonitoringListReducer?.monitoringFilterData);
    const [monitoringHubConnection, setMonitoringHubConnection] = useState(null) as any;
    const [allMonitoring, setAllMonitoring] = useState<any>(null);
    const [allStatuses, setAllStatuses] = useState<any>(null);
    const autoReload = useAppSelector(state => state.MonitoringListReducer.autoReload);
    const reconnectSession = useAppSelector(state => state.WebRTCReducer.reconnectSession);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const internetConnection = (navigator as any).connection as NetworkInformation | undefined;

    let decodedToken: DecodedToken;
    if (jwtToken?.jwtToken) {
        decodedToken = jwtDecode(jwtToken?.jwtToken);
    }
    const [sortData, setSortData] = useState<any>({
        pageNumber: 1,
        pageSize: 10,
        sort: {
            orderBy: orderBy ? orderBy : "Id",
            isDesc: isDescFilter
        },
        filter: [],
        username: user.userName
    });

    const [networkInfo, setNetworkInfo] = useState({
        downlink: internetConnection?.downlink ?? null,
        effectiveType: internetConnection?.effectiveType ?? null
    });

    useEffect(() => {
        const updateNetworkInfo = () => {
            setIsOnline(navigator.onLine);
            setNetworkInfo({
                downlink: internetConnection?.downlink ?? null,
                effectiveType: internetConnection?.effectiveType ?? null
            });
        };

        window.addEventListener('online', updateNetworkInfo);
        window.addEventListener('offline', updateNetworkInfo);
        internetConnection?.addEventListener('change', updateNetworkInfo);

        return () => {
            window.removeEventListener('online', updateNetworkInfo);
            window.removeEventListener('offline', updateNetworkInfo);
            internetConnection?.removeEventListener('change', updateNetworkInfo);
        };
    }, [internetConnection]);

    function startConnection() {
        const connection = new HubConnectionBuilder()
            .withUrl(`${WSS_URL}MonitoringHub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => jwtToken?.jwtToken || ""
            })
            .withAutomaticReconnect()
            .build();

        connection.serverTimeoutInMilliseconds = 60000;

        connection.onreconnected(() => {
            console.log(`[${new Date().toISOString()}] Information: WebSocket reconnected to ${WSS_URL}MonitoringHub`);
            connection.invoke("UserConnected", decodedToken[`x-access-userid`]).then(() => {
                dispatch(setMonitoringHubError(false));
            })
                .catch(() => {
                    dispatch(setMonitoringHubError(true));
                }).catch(err => console.error(err));
        });

        connection.onreconnecting((error) => {
            console.warn(`[${new Date().toISOString()}] Warning: WebSocket reconnecting...`, error);
        });


        connection.onclose((error) => {
            // console.error(`[${new Date().toISOString()}] Error: WebSocket connection closed.`, error);
            dispatch(setMonitoringHubError(true));
        });


        connection.start()
            .then(() => {
                setMonitoringHubConnection(connection);
                dispatch(setMonitoringHubError(false));
                connection.on('MonitorUserFirstEvent', AllCampaigns => {
                    setAllMonitoring(JSON.parse(AllCampaigns));
                });
                connection.on('MonitorUserEvent', AllStatuses => {
                    setAllMonitoring(null);
                    // console.log(JSON.parse(AllStatuses))
                    setAllStatuses(JSON.parse(AllStatuses));
                });
                connection.invoke("UserConnected", decodedToken[`x-access-userid`]).then(() => {
                    dispatch(setMonitoringHubError(false));
                })
                    .catch(() => {
                        dispatch(setMonitoringHubError(true));
                    });
            })
            .catch(() => {
                dispatch(setMonitoringHubError(true));
                setMonitoringHubConnection(null);
                setTimeout(startConnection, 10000);
            });
        return connection;
    }

    let connection: any;

    useEffect(() => {
        if (!reconnectSession) {
            connection = startConnection();
            return () => {
                connection.stop();
            };
        } else {
            if (monitoringHubConnection && monitoringHubConnection.state === 'Disconnecting') {
                monitoringHubConnection.stop();
                connection = startConnection();
            }
        }
    }, [reconnectSession]);


    useEffect(() => {
        if (isOnline) {
            if (monitoringHubConnection) {
                monitoringHubConnection.stop();
                connection = startConnection();
                dispatch(setMonitoringHubError(false));
            } else {
                dispatch(setMonitoringHubError(true));
            }
        }
    }, [isOnline]);

    useEffect(() => {
        const getMonitoringHubConnection = () => {
            if (isOnline) {
                dispatch(setMonitoringHubError(!(monitoringHubConnection?.state === 'Connecting' || monitoringHubConnection?.state === 'Connected')));
            } else {
                dispatch(setMonitoringHubError(true));
            }
        };

        const intervalId = setInterval(getMonitoringHubConnection, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, [monitoringHubConnection, isOnline]);

    useEffect(() => {
        if (allStatuses && !allStatuses.SessionId && allStatuses.length) {
            if (monitoringData?.id) {
                const foundCallDetail = allStatuses?.find((callDetail: { Id: any; }) => callDetail.Id === monitoringData?.id);
                if (foundCallDetail) {
                    const foundCallDetailId = foundCallDetail?.Id;
                    if (foundCallDetailId !== undefined) {
                        dispatch(getEventMonitorToUserDetail(foundCallDetailId, jwtToken?.jwtToken));
                    }
                }
            }
        }
    }, [allStatuses]);

    function keysFirstLetterToLowerCase(obj: any): any {
        if (Array?.isArray(obj)) {
            return obj.map(item => keysFirstLetterToLowerCase(item));
        } else if (obj !== null && typeof obj === 'object') {
            return Object.keys(obj).reduce((acc: any, key: string) => {
                const firstLetterLowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
                acc[firstLetterLowerCaseKey] = keysFirstLetterToLowerCase(obj[key]);
                return acc;
            }, {});
        }
        return obj;
    }


    useEffect(() => {
        function updateDataByGroupSessionIdWithNew(data: any[], newData: any[], filters: any): any[] {
            const existingIds = new Set<number>(data.map(obj => obj.item?.groupSessionId));

            let newEntries: any[] = [];

            function passesAllFilters(newObj: { item: any }, filters: any[]) {
                const nameToFilter = filters?.find(filter => filter.key === "ANI");
                const ownerCallFilters = filters?.filter(filter => filter.key === "OwnerCall");
                const usernameFilters = filters?.filter(filter => filter.key === "Username");
                const directionFilters = filters?.filter(filter => filter.key === "Direction");
                const campaignIdFilters = filters?.filter(filter => filter.key === "CampaignId");
                const queueIdFilters = filters?.filter(filter => filter.key === "QueueId");
                const typeChannelFilters = filters?.filter(filter => filter.key === "CallType");
                const callResetFilters = filters?.filter(filter => filter.key === "CallReset");
                const forcedClosingFilters = filters?.filter(filter => filter.key === "ForcedClosing");
                const startFilter = filters?.find(filter => filter.key === "Start");
                const endFilter = filters?.find(filter => filter.key === "End");
                const durationFilter = filters?.find(filter => filter.key === "Duration");
                const fullCallDurationFilter = filters?.find(filter => filter.key === "FullCallDuration");
                const reactionClientDurationFilter = filters?.find(filter => filter.key === "ReactionClient");
                const reactionOperatorDurationFilter = filters?.find(filter => filter.key === "ReactionOperator");
                const statusFilter = filters?.filter((filter: { key: string; }) => filter.key === "Status");

                if (nameToFilter && !newObj.item?.aNI?.includes(nameToFilter.value)) {
                    return false;
                }

                if (
                    ownerCallFilters?.length &&
                    !ownerCallFilters?.some((filter: { value: string }) => {
                        const ownerCall = newObj.item?.ownerCall;
                        return (Array.isArray(ownerCall) && ownerCall?.includes(filter?.value)) ||
                            (typeof ownerCall === 'string' && ownerCall === filter?.value);
                    })
                ) {
                    return false;
                }

                if (usernameFilters?.length && !usernameFilters.some((filter: { value: any; }) => newObj.item?.username?.includes(filter.value))) {
                    return false;
                }

                if (
                    directionFilters?.length &&
                    !directionFilters?.some((filter: { value: any }) => {
                        const direction = newObj.item?.direction;
                        return (Array?.isArray(direction) && direction?.includes(filter.value)) ||
                            (typeof direction === 'string' && direction === filter.value);
                    })
                ) {
                    return false;
                }

                if (
                    campaignIdFilters?.length &&
                    !campaignIdFilters.some((filter: { value: any }) => {
                        const campaignId = newObj.item?.campaignId;

                        if (Array.isArray(campaignId)) {
                            return campaignId.includes(filter.value);
                        }

                        return String(campaignId) === String(filter.value);
                    })
                ) {
                    return false;
                }

                if (
                    queueIdFilters?.length &&
                    !queueIdFilters.some((filter: { value: any }) => {
                        const queueId = newObj.item?.queueId;

                        return (
                            (Array.isArray(queueId) && queueId.includes(filter.value)) ||
                            (typeof queueId === 'string' && queueId === String(filter.value)) ||
                            (typeof queueId === 'number' && queueId === Number(filter.value))
                        );
                    })
                ) {
                    return false;
                }

                if (typeChannelFilters?.length && !typeChannelFilters.some((filter: { value: any; }) => newObj.item?.callType?.includes(filter.value))) {
                    return false;
                }

                if (callResetFilters?.length && !callResetFilters.some((filter: { value: string; }) => String(newObj.item?.callReset) === filter.value)) {
                    return false;
                }

                if (forcedClosingFilters?.length && !forcedClosingFilters.some((filter: { value: string; }) => String(newObj.item?.forcedClosing) === filter.value)) {
                    return false;
                }

                if (startFilter) {
                    const [startFilterDateStr, endFilterDateStr] = startFilter?.value?.split(',');

                    let startFilterDate = new Date(startFilterDateStr);
                    let endFilterDate = new Date(endFilterDateStr);

                    let newObjStartDate = new Date(newObj.item?.start);

                    if (newObjStartDate < startFilterDate || newObjStartDate > endFilterDate) {
                        return false;
                    }
                }

                if (endFilter) {
                    const [startFilterDateStr, endFilterDateStr] = endFilter?.value?.split(',');

                    let startFilterDate = new Date(startFilterDateStr);
                    let endFilterDate = new Date(endFilterDateStr);

                    let newObjStartDate = new Date(newObj.item?.end);

                    if (newObjStartDate < startFilterDate || newObjStartDate > endFilterDate) {
                        return false;
                    } else {
                    }
                }

                if (durationFilter) {
                    const [minDuration, maxDuration] = durationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.duration;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (fullCallDurationFilter) {
                    const [minDuration, maxDuration] = fullCallDurationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.fullCallDuration;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (reactionClientDurationFilter) {
                    const [minDuration, maxDuration] = reactionClientDurationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.reactionClient;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (reactionOperatorDurationFilter) {
                    const [minDuration, maxDuration] = reactionOperatorDurationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.reactionOperator;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (statusFilter.length > 0) {
                    const statusMatch = statusFilter?.some((filter: { value: any; }) => String(newObj.item?.status) === filter.value);
                    if (!statusMatch) {
                        return;
                    }
                }

                const hasOnlyStatus101Filter = statusFilter?.length === 1 && statusFilter?.[0]?.value === "101";

                if (hasOnlyStatus101Filter) {
                    const existingObject = newEntries?.find(existingObj => existingObj.item?.groupSessionId === newObj.item?.groupSessionId);
                    if (existingObject && existingObject.item?.status === "101" && newObj.item?.status !== "101") {
                        newEntries = newEntries.filter(existingObj => existingObj.item?.groupSessionId !== newObj.item?.groupSessionId);
                    }
                }

                return true;
            }

            const updateOrAddChild = (children: any[], newChild: any) => {
                const existingIndex = children.findIndex(child => child.item?.id === newChild.item?.id);

                if (existingIndex > -1) {
                    return children.map((child, index) =>
                        index === existingIndex
                            ? {
                                ...child,
                                ...newChild,
                                children: updateDataByGroupSessionIdWithNew(
                                    child.children || [],
                                    newChild.children || [],
                                    []
                                )
                            }
                            : child
                    );
                } else {
                    return [...children, newChild];
                }
            };

            const updatedData = data
                .map(obj => {
                    const newObj = newData.find(newObj => newObj.item?.groupSessionId === obj.item?.groupSessionId);

                    if (newObj) {
                        const passedFilters = passesAllFilters(newObj, filters);
                        if (passedFilters) {
                            return {
                                ...obj,
                                item: {
                                    ...obj.item,
                                    ...newObj.item
                                },
                                children: newObj.children
                                    ? newObj.children.reduce(
                                        (updatedChildren: any[], newChild: any) => updateOrAddChild(updatedChildren, newChild),
                                        cleanChildren(obj.children, newData)
                                    )
                                    : undefined
                            };
                        }
                        return null;
                    }
                    return obj;
                })
                .filter(Boolean);

            function cleanChildren(children: any[], newData: any[]): any[] {
                if (!children) return [];

                return children
                    .map(child => {
                        const existsInNewData = newData.some(newObj => newObj.item?.bSessionId === child.item?.bSessionId);
                        if (!existsInNewData) return null;

                        return {
                            ...child,
                            children: cleanChildren(child.children, newData)
                        };
                    })
                    .filter(Boolean);
            }

            newData.forEach(newObj => {
                const nameToFilter = filters.find((filter: { key: string; }) => filter.key === "ANI");
                const ownerCallFilters = filters.filter((filter: { key: string; }) => filter.key === "OwnerUserName");
                const usernameFilters = filters?.filter((filter: { key: string; }) => filter.key === "Username");
                const directionFilters = filters?.filter((filter: { key: string; }) => filter.key === "Direction");
                const ownerTypeFilters = filters?.filter((filter: { key: string; }) => filter.key === "OwnerType");
                const campaignIdFilters = filters?.filter((filter: { key: string; }) => filter.key === "CampaignId");
                const queueIdFilters = filters?.filter((filter: { key: string; }) => filter.key === "QueueId");
                const statusFilter = filters?.filter((filter: { key: string; }) => filter.key === "Status");
                const typeChannelFilters = filters?.filter((filter: { key: string; }) => filter.key === "CallType");
                const callResetFilters = filters?.filter((filter: { key: string; }) => filter.key === "CallReset");
                const forcedClosing = filters?.filter((filter: { key: string; }) => filter.key === "ForcedClosing");
                const startFilter = filters.find((filter: { key: string; }) => filter.key === "Start");
                const endFilter = filters?.find((filter: { key: string; }) => filter.key === "End");
                const fullCallDurationFilter = filters?.find((filter: { key: string; }) => filter.key === "FullCallDuration");
                const durationFilter = filters?.find((filter: { key: string; }) => filter.key === "Duration");
                const reactionClientDurationFilter = filters?.find((filter: { key: string; }) => filter.key === "ReactionClient");
                const reactionOperatorDurationFilter = filters?.find((filter: { key: string; }) => filter.key === "ReactionOperator");

                const passedFilters = passesAllFilters(newObj, filters);

                if (startFilter) {
                    const [startFilterDateStr, endFilterDateStr] = startFilter?.value?.split(',');

                    let startFilterDate = new Date(startFilterDateStr);
                    let endFilterDate = new Date(endFilterDateStr);

                    let newObjStartDate = new Date(newObj.item?.start);

                    if (newObjStartDate < startFilterDate || newObjStartDate > endFilterDate) {
                        return;
                    }
                }

                if (endFilter) {
                    const [startFilterDateStr, endFilterDateStr] = endFilter?.value?.split(',');

                    let startFilterDate = new Date(startFilterDateStr);
                    let endFilterDate = new Date(endFilterDateStr);

                    let newObjStartDate = new Date(newObj.item?.end);

                    if (newObjStartDate < startFilterDate || newObjStartDate > endFilterDate) {
                        return false;
                    } else {
                    }
                }

                if (durationFilter) {
                    const [minDuration, maxDuration] = durationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.duration;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (fullCallDurationFilter) {
                    const [minDuration, maxDuration] = fullCallDurationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.fullCallDuration;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (reactionClientDurationFilter) {
                    const [minDuration, maxDuration] = reactionClientDurationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.reactionClient;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (reactionOperatorDurationFilter) {
                    const [minDuration, maxDuration] = reactionOperatorDurationFilter?.value?.split(',').map((num: any) => Number(num.trim()));

                    if (!isNaN(minDuration) && !isNaN(maxDuration)) {
                        const durationValue = newObj.item?.reactionOperator;
                        if (durationValue < minDuration || durationValue > maxDuration) {
                            return false;
                        }
                    }
                }

                if (statusFilter.length > 0) {
                    const statusMatch = statusFilter?.some((filter: { value: any; }) => String(newObj.item?.status) === filter.value);
                    if (!statusMatch) {
                        return;
                    }
                }

                const hasOnlyStatus101Filter = statusFilter?.length === 1 && statusFilter?.[0]?.value === "101";

                if (hasOnlyStatus101Filter) {
                    const existingObject = newEntries?.find(existingObj => existingObj.item?.groupSessionId === newObj.item?.groupSessionId);
                    if (existingObject && existingObject.item?.status === "101" && newObj.item?.status !== "101") {
                        newEntries = newEntries.filter(existingObj => existingObj.item?.groupSessionId !== newObj.item?.groupSessionId);
                    }
                }

                if (
                    !existingIds.has(newObj.item?.groupSessionId) &&
                    passedFilters &&
                    (!nameToFilter || (newObj.item?.aNI || '').includes(nameToFilter.value)) &&
                    (!ownerCallFilters?.length || ownerCallFilters.some((filter: { value: any; }) => (newObj.item?.ownerUserName || '').includes(filter.value))) &&
                    (!forcedClosing?.length || forcedClosing.some((filter: { value: string; }) => String(newObj.item?.forcedClosing) === filter.value)) &&
                    (!callResetFilters?.length || callResetFilters.some((filter: { value: string; }) => String(newObj.item?.callReset) === filter.value)) &&
                    (!typeChannelFilters?.length || typeChannelFilters.some((filter: { value: any; }) => (newObj.item?.callType || '').includes(filter.value))) &&
                    (!queueIdFilters?.length || queueIdFilters.some((filter: { value: string; }) => String(newObj.item?.queueId) === filter.value)) &&
                    (!campaignIdFilters?.length || campaignIdFilters.some((filter: { value: string; }) => String(newObj.item?.campaignId) === filter.value)) &&
                    (!directionFilters?.length || directionFilters.some((filter: { value: any; }) => (newObj.item?.direction || '').includes(filter.value))) &&
                    (!ownerTypeFilters?.length || ownerTypeFilters.some((filter: { value: string; }) => String(newObj.item?.ownerType) === filter.value)) &&
                    (!usernameFilters?.length || usernameFilters.some((filter: { value: any; }) => (newObj.item?.username || '').includes(filter.value)))
                ) {
                    newEntries.unshift(newObj); // Добавляем объект только если он проходит все фильтры
                }

            });

            return [...newEntries, ...updatedData];
        }

        if (allStatuses?.length && autoReload) {
            if (paginationPage === 1) {
                let newMonitoringData = keysFirstLetterToLowerCase(allStatuses);

                let filteredMonitoringList = monitoringList?.filter(
                    (obj: { item: { groupSessionId: any; }; }) => obj?.item?.groupSessionId !== newMonitoringData[0]?.item?.groupSessionId
                );

                let newList = updateDataByGroupSessionIdWithNew(filteredMonitoringList, newMonitoringData, monitoringFilterData?.filter);

                newList?.sort((a, b) => new Date(b.item?.start)?.getTime() - new Date(a.item?.start).getTime());

                dispatch({ type: GET_MONITORINGS, payload: newList });
            }
        }

        if (monitoringData?.id && allStatuses?.length) {
            const getAllGroupSessionIds = (arr: any[]): any[] => {
                let ids: any[] = [];

                arr.forEach(obj => {
                    if (obj.item && obj.item.id) {
                        ids.push(obj.item.id);
                    }

                    if (obj.children && obj.children.length > 0) {
                        ids = ids.concat(getAllGroupSessionIds(obj.children));
                    }
                });

                return ids;
            };

            let newMonitoringData = keysFirstLetterToLowerCase(allStatuses);
            let allIds = getAllGroupSessionIds(newMonitoringData);
            const foundId = allIds.find(id => id === monitoringData.id);

            if (foundId) {
                const findGroupSessionId = (arr: any[], groupSessionId: number): { id: number } | undefined => {
                    for (const obj of arr) {
                        if (obj.item && obj.item.id === groupSessionId) {
                            return obj.item;
                        }

                        if (obj.children && obj.children.length > 0) {
                            const found = findGroupSessionId(obj.children, groupSessionId);
                            if (found) {
                                return found;
                            }
                        }
                    }

                    return undefined;
                };

                const foundObject = findGroupSessionId(newMonitoringData, monitoringData.id);

                if (foundObject?.id) {
                    dispatch({type: GET_MONITORING, payload: foundObject});
                } else {
                    dispatch(getEventMonitorToUserDetail(monitoringData?.id, jwtToken?.jwtToken));
                }
            }
        }
    }, [allStatuses, autoReload, monitoringFilterData]);

    useEffect(() => {
        if (allStatuses && allStatuses?.length) {
            let newMonitoringData = keysFirstLetterToLowerCase(allStatuses);
            const getAllObjectByNameTo = (arr: any[]): any[] => {
                let ids: any[] = [];

                arr.forEach(obj => {
                    if (obj.item && obj.item.aNI) {
                        ids.push(obj.item.aNI);
                    }

                    if (obj.children && obj.children.length > 0) {
                        ids = ids.concat(getAllObjectByNameTo(obj.children));
                    }
                });

                return ids;
            };

            const getAllObjectByAni = (arr: any[]): any[] => {
                let ids: any[] = [];

                arr.forEach(obj => {
                    if (obj.item && obj.item.aNI) {
                        ids.push(obj.item.aNI);
                    }

                    if (obj.children && obj.children.length > 0) {
                        ids = ids.concat(getAllObjectByAni(obj.children));
                    }
                });

                return ids;
            };

            const getAllObjectByUsername = (arr: any[]): any[] => {
                let ids: any[] = [];

                arr.forEach(obj => {
                    if (obj.item && obj.item.userName) {
                        ids.push(obj.item.userName);
                    }

                    if (obj.children && obj.children.length > 0) {
                        ids = ids.concat(getAllObjectByUsername(obj.children));
                    }
                });

                return ids;
            };

            let allNameTo = getAllObjectByNameTo(newMonitoringData);
            let allUsername = getAllObjectByUsername(newMonitoringData);
            let allANI = getAllObjectByAni(newMonitoringData);

            const foundNameTo = allNameTo.find(nameTo => nameTo === user.userName);
            const foundUsername = allUsername.find(username => username === user.userName);
            const foundAni = allANI.find(username => username === user.userName);

            if (foundNameTo || foundUsername || foundAni) {
                const findObject = (arr: any[], userName: any): any | undefined => {
                    for (const obj of arr) {
                        if (obj.item && (obj.item.aNI === user.userName || obj.item.username === user.userName || obj.item.aNI === user.userName)) {
                            return obj.item;
                        }

                        if (obj.children && obj.children.length > 0) {
                            const found = findObject(obj.children, userName);
                            if (found) {
                                return found;
                            }
                        }
                    }

                    return undefined;
                };

                const foundObject = findObject(newMonitoringData, user?.userName);

                if (foundObject?.status === 101) {
                    // setSelectedRowData(foundObject);
                    // setVisible(true);
                    // dispatch({type: GET_MONITORING_FOR_SIP, payload: foundObject});
                }
            }
        }
    }, [allStatuses]);

    const resetTableHorizontalScroll = () => {
        const scrollableBody = document.querySelector('.p-datatable-wrapper');
        if (scrollableBody) {
            scrollableBody.scrollTop = 0;
            scrollableBody.scrollLeft = 0;
        }
    };

    if (!userSortFields?.userId) {
        dispatch(getUserSortFields(jwtToken?.jwtToken));
    }

    useEffect(() => {
        if (pagination.hasNext && pagination.pageNumber) {
            setTotalItems((pagination.pageNumber * paginationSize) + paginationSize)
        } else if (!pagination.hasNext) {
            setTotalItems(pagination.pageNumber * paginationSize);
        }
    }, [pagination]);

    useEffect(() => {
        setTotalItems((pagination.pageNumber * paginationSize) + paginationSize);
    }, []);


    useEffect(() => {
        setSelectedColumns(userSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    }, []);

    useEffect(() => {
        setFirst(0);
    }, [pageChanged])

    useEffect(() => {
        setSelectedColumns(userSortFields?.fields?.filter((item: { isActive: any; }) => item.isActive));
    }, [userSortFields]);

    const createUuidv4 = () => {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            // @ts-ignore
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const formatDateTime = (dateString: any) => {
        const date = new Date(dateString);
        return `${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})} ${date.toLocaleDateString('en-GB')}`;
    };

    const formatItem = (item: { start: any; }) => {
        if (item && item.start) {
            return {
                ...item,
                start: formatDateTime(item.start),
                dateTime: createUuidv4()
            };
        }
        return item;
    };

    const formatDataRecursively = (obj: any) => {
        const formattedObj = {
            ...obj,
            item: formatItem(obj.item),
        };

        if (formattedObj.children && formattedObj.children.length > 0) {
            formattedObj.children = formattedObj.children.map((child: any) => formatDataRecursively(child));
        }

        return formattedObj;
    };

    const formattedData = monitoringList?.map(formatDataRecursively);

    useEffect(() => {
        handleResetPlayer();
    }, [selectedRowData]);

    const handleReset = () => {
        dataTableRef?.current?.reset();
        customFilter();
        setStartTime(null);
        setEndTime(null);
        setFilterEndDateTime(null);
        setStartFinishTime(null);
        setEndFinishTime(null);
        setFilterFinishDateTime(null);
        setFilterFinishEndDateTime(null);
        setSortOrder(0);
        setFirst(0);
        dispatch(setCurrentPage(1));
        dispatch(setCurrentPageSize(10));
        setReactionClient(false);
        setReactionOperator(false);
        setFullCallDuration(false);
        setDuration(false);
        setReactionOperatorStartTime(null);
        setReactionOperatorEndTime(null);
        setReactionOperator(false);
        setDurationStartTime(null);
        setDurationEndTime(null);
        setfullCallDurationStartTime(null);
        setfullCallDurationEndTime(null);
        setReactionClientStartTime(null);
        setReactionClientEndTime(null);
        setReactionClient(false);
    };

    useEffect(() => {
        dispatch(getQueueDirectoriesQueueDirectionsType(jwtToken?.jwtToken));
        dispatch(getCampaignFilterList(jwtToken?.jwtToken));
        dispatch(getStatusDirectoriesStatusType(jwtToken?.jwtToken));
        dispatch(getCallResetType(jwtToken?.jwtToken));
        dispatch(getForcedClosingType(jwtToken?.jwtToken));
        dispatch(getOwnerTypeDictionary(jwtToken?.jwtToken));
        dispatch(getDirectionDictionary(jwtToken?.jwtToken));
        dispatch(getCallTypeDictionary(jwtToken?.jwtToken));
    }, []);

    useEffect(() => {
        dispatch(setCurrentPage(1));
    }, []);

    useEffect(() => {
        dispatch(setCurrentPageSize(10));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onPageChange = (event: { first: React.SetStateAction<number>; page: number; rows: any; }) => {
        setFirst(event.first);
        dispatch(setCurrentPage(event.page + 1));
        dispatch(setCurrentPageSize(event.rows));
    };

    const formatDate = (dateString: Date) => {
        const dateObj = new Date(dateString);

        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        const year = dateObj.getFullYear();

        return `${month}/${day}/${year}`;
    };

    const getTime = (dateString: Date) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");
        const seconds = dateObj.getSeconds().toString().padStart(2, "0");

        return `${hours}:${minutes}:${seconds}`;
    };

    const getTimeWithoutSecond = (dateString: Date) => {
        const dateObj = new Date(dateString);
        const hours = dateObj.getHours().toString().padStart(2, "0");
        const minutes = dateObj.getMinutes().toString().padStart(2, "0");

        return `${hours}:${minutes}`;
    };


    useEffect(() => {
        // setSelectedRowData(null);
        let filter: {
            key: string;
            value: string
        }[] = [];

        const dataSort: any = {
            pageNumber: paginationPage,
            pageSize: paginationSize,
            sort: {
                orderBy: orderBy ? orderBy : "Id",
                isDesc: isDescFilter
            },
            username: user.userName
        }

        if (filterCampaignId?.value) {
            filter.push(filterCampaignId);
        }
        if (filterNameTo?.value) {
            filter.push(filterNameTo);
        }
        if (filterNameFrom?.value) {
            filter.push(filterNameFrom);
        }
        if (filterTypeChannel) {
            filterTypeChannel?.map((item: any) => {
                if (item.value === 'internalSkipped') {
                    filter.push({key: 'IsDeclined', value: '1'});
                } else {
                    filter.push(item);
                }
            })
        }

        if (filterCampaignName) {
            filterCampaignName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterCallOwner) {
            filterCallOwner?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterOwnerType) {
            filterOwnerType?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterQueueName) {
            filterQueueName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterFullName) {
            filterFullName?.map((item: any) => {
                filter.push(item);
            })
        }

        if (filterStatus) {
            filterStatus.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterDirection) {
            filterDirection.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterAmdHangup) {
            filterAmdHangup.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterCallReset) {
            filterCallReset.map((item: any) => {
                filter.push(item);
            })
        }
        if (filterDateTime && filterEndDateTime) {
            let startDate = formatDate(filterDateTime);
            let endDate = formatDate(filterEndDateTime);

            if (startTime && endTime) {
                let filterStartTime = getTime(startTime);
                let filterEndTime = getTime(endTime);
                filter.push({
                    "key": "Start",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                })
            }
        }
        if (!filterDateTime) {
            if (startTime && endTime) {
                let filterStartTime = getTimeWithoutSecond(startTime);
                let filterEndTime = getTimeWithoutSecond(endTime);
                filter.push({
                    "key": "Start",
                    "value": `${filterStartTime},${filterEndTime}`
                })
            }
        }

        if (filterFinishDateTime && filterFinishEndDateTime) {
            let startDate = formatDate(filterFinishDateTime);
            let endDate = formatDate(filterFinishEndDateTime);

            if (startFinishTime && endFinishTime) {
                let filterStartTime = getTime(startFinishTime);
                let filterEndTime = getTime(endFinishTime);
                filter.push({
                    "key": "End",
                    "value": `${startDate} ${filterStartTime},${endDate} ${filterEndTime}`
                })
            }
        }
        if (!filterFinishDateTime) {
            if (startFinishTime && endFinishTime) {
                let filterStartTime = getTimeWithoutSecond(startFinishTime);
                let filterEndTime = getTimeWithoutSecond(endFinishTime);
                filter.push({
                    "key": "End",
                    "value": `${filterStartTime},${filterEndTime}`
                })
            }
        }

        const addFilterSecond = (condition: boolean, key: string, startTime: number, endTime: number) => {
            if (
                condition &&
                startTime !== undefined && startTime !== null &&
                endTime !== undefined && endTime !== null
            ) {
                filter.push({
                    "key": key,
                    "value": `${startTime}, ${endTime}`
                });
            }
        };

        const getTotalSeconds = (date: any) => {
            if (!date) return null;
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            return hours * 3600 + minutes * 60 + seconds;
        };


        if (reactionClientStartTime && reactionClientEndTime) {
            addFilterSecond(reactionClient, "ReactionClient", getTotalSeconds(reactionClientStartTime), getTotalSeconds(reactionClientEndTime));
        }
        if (reactionOperatorStartTime && reactionOperatorEndTime) {
            addFilterSecond(reactionOperator, "ReactionOperator", getTotalSeconds(reactionOperatorStartTime), getTotalSeconds(reactionOperatorEndTime));
        }
        if (fullCallDurationStartTime && fullCallDurationEndTime) {
            addFilterSecond(fullCallDuration, "FullCallDuration", getTotalSeconds(fullCallDurationStartTime), getTotalSeconds(fullCallDurationEndTime));
        }
        if (durationStartTime && durationEndTime) {
            addFilterSecond(duration, "Duration", getTotalSeconds(durationStartTime), getTotalSeconds(durationEndTime));
        }

        if (filter) {
            dataSort['filter'] = filter;
        }
        collapseAll();
        dispatch(getMonitoringWithPaginationFilterAndSorting(dataSort, jwtToken?.jwtToken));
        dispatch(setMonitoringFilter(dataSort));
        setSortData(dataSort);
        resetTableHorizontalScroll();
    }, [paginationPage, paginationSize, orderBy, isDescFilter, sortOrder, filterCampaignId, filterNameTo, filterTypeChannel, filterStatus, reactionClient, reactionOperator, duration, fullCallDuration]);

    useEffect(() => {
        if (activeClick?.item === "/monitoring" && activeClick?.from === '#/monitoring') {
            dispatch(getMonitoringWithPaginationFilterAndSorting(sortData, jwtToken?.jwtToken));
            resetTableHorizontalScroll();
        }
    }, [activeClick]);

    const monitoringSort = (event: { sortField: any; }) => {
        const handleAction = (sortField: string) => {
            let newOrderBy: string;

            switch (sortField) {
                case 'item.userName':
                    newOrderBy = 'UserName';
                    break;
                case 'item.callType':
                    newOrderBy = 'CallType';
                    break;
                case 'item.start':
                    newOrderBy = 'Start';
                    break;
                case 'item.status':
                    newOrderBy = 'Status';
                    break;
                case 'item.ani':
                    newOrderBy = 'Ani';
                    break;
                case 'item.campaignName':
                    newOrderBy = 'CampaignName';
                    break;
                case 'item.campaignId':
                    newOrderBy = 'CampaignId';
                    break;
                case 'item.callReset':
                    newOrderBy = 'CallReset';
                    break;
                case 'forcedClosing':
                    newOrderBy = 'ForcedClosing';
                    break;
                case 'item.fullName':
                    newOrderBy = 'Username';
                    break;
                case 'item.direction':
                    newOrderBy = 'Direction';
                    break;
                case 'item.queueId':
                    newOrderBy = 'QueueId';
                    break;
                case 'item.ownerUserName':
                    newOrderBy = 'OwnerUserName';
                    break;
                case 'FullCallDuration':
                    newOrderBy = 'FullCallDuration';
                    break;
                case 'Duration':
                    newOrderBy = 'Duration';
                    break;
                case 'ReactionOperator':
                    newOrderBy = 'ReactionOperator';
                    break;
                case 'ReactionClient':
                    newOrderBy = 'ReactionClient';
                    break;
                case 'End':
                    newOrderBy = 'End';
                    break;
                case 'OwnerType':
                    newOrderBy = 'OwnerType';
                    break;
                default:
                    return;
            }

            setOrderBy(newOrderBy);
            setIsDescFilter(!isDescFilter);
            setSortOrder(sortOrder === 1 ? -1 : 1);
            dispatch(setCurrentPage(1));
            setFirst(0);
        };
        let sortField = event.sortField;
        handleAction(sortField);
    };

    const filterClearTemplate = (options: { filterClearCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string }) => {
        return <Button type="button" className="p-button-secondary" icon="pi pi-times" onClick={(e) => {
            if (options.filterClearCallback) {
                options.filterClearCallback(e);
            }
            if (options.field === 'start') {
                setStartTime(null);
                setEndTime(null);
                setFilterDateTime(null);
                setFilterEndDateTime(null);
            }
            if (options.field === 'end') {
                setStartFinishTime(null);
                setEndFinishTime(null);
                setFilterFinishDateTime(null);
                setFilterFinishEndDateTime(null);
            }
            if (options.field === 'reactionClient') {
                setReactionClientStartTime(null);
                setReactionClientEndTime(null);
                setReactionClient(false);
            }
            if (options.field === 'reactionOperator') {
                setReactionOperatorStartTime(null);
                setReactionOperatorEndTime(null);
                setReactionOperator(false);
            }
            if (options.field === 'duration') {
                setDuration(false);
                setDurationStartTime(null);
                setDurationEndTime(null);
            }
            if (options.field === 'fullCallDuration') {
                setfullCallDurationStartTime(null);
                setfullCallDurationEndTime(null);
                setFullCallDuration(false);
            }
        }}></Button>;
    }

    const filterApplyTemplate = (options: { filterApplyCallback: React.MouseEventHandler<HTMLButtonElement> | undefined; field: string; }) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} className=""
                       disabled={
                           (options.field === 'duration' && (!durationStartTime || !durationEndTime)) ||
                           (options.field === 'fullCallDuration' && (!fullCallDurationStartTime || !fullCallDurationEndTime)) ||
                           (options.field === 'reactionOperator' && (!reactionOperatorStartTime || !reactionOperatorEndTime)) ||
                           (options.field === 'reactionClient' && (!reactionClientStartTime || !reactionClientEndTime)) ||
                           (options.field === 'start' && (!startTime || !endTime || !filterDateTime || !filterEndDateTime)) ||
                           (options.field === 'end' && (!startFinishTime || !endFinishTime || !filterFinishDateTime || !filterFinishEndDateTime))}></Button>
    }

    useEffect(() => {
        dispatch(getAllSimpleUsers(user));
    }, []);

    const customFilter = (event?: any) => {
        if (event) {
            const filters = event.filters;
            const {start} = event.filters;
            const {end} = event.filters;
            const {fullCallDuration} = event.filters;
            const {duration} = event.filters;
            const {reactionClient} = event.filters;
            const {reactionOperator} = event.filters;

            setFilterDateTime(start.value || null);
            setFullCallDuration(!!fullCallDuration.value);
            setDuration(!!duration.value);
            setReactionClient(!!reactionClient.value);
            setReactionOperator(!!reactionOperator.value);
            setFilterFinishDateTime(end.value || null);
            const setFilter = (filterName: string, key: string, defaultValue?: any | null) => {
                if (!filters[filterName]) {
                    return defaultValue;
                }

                const value = filters[filterName].value || filters[filterName].constraints?.[0]?.value;
                if (!value) {
                    return defaultValue;
                }

                if (Array?.isArray(value)) {
                    return value.map((item) => ({key, value: String(item)}));
                } else {
                    return {key, value: String(value)};
                }
            };

            setFilterCampaignId(setFilter('campaignId', 'CampaignId'));
            setFilterNameTo(setFilter('ani', 'ANI'));
            setFilterNameFrom(setFilter('userName', 'UserName'));
            setFilterTypeChannel(setFilter('callType', 'CallType', []));
            setFilterOwnerType(setFilter('ownerType', 'OwnerType', []));
            setFilterFullName(setFilter('fullName', 'UserName', []));
            setFilterCampaignName(setFilter('campaignFullName', 'CampaignId', []));
            setFilterCallOwner(setFilter('ownerUserName', 'OwnerUserName', []));
            setFilterQueueName(setFilter('queueFullName', 'QueueId', []));
            setFilterDirection(setFilter('direction', 'Direction', []));
            setFilterStatus(setFilter('status', 'Status', []));
            setFilterAmdHangup(setFilter('forcedClosing', 'ForcedClosing', []));
            setFilterCallReset(setFilter('callResetName', 'CallReset', []));
        } else {
            setFilterCampaignId(null);
            setFilterFullName(null);
            setFilterNameTo(null);
            setFilterNameFrom(null);
            setFilterStatus(null);
            setFilterCampaignName(null);
            setFilterTypeChannel(null);
            setFilterDateTime(null);
            setFilterAmdHangup(null);
            setFilterCallReset(null);
            setFilterOwnerType(null);
            setOrderBy(null);
            setIsDescFilter(true);
            setFilterDirection(null);
            setFilterQueueName(null);
            setFilterCallOwner(null);
        }
    };

    const renderHeader = (field: string, sortField: string) => {
        const sorted = sortField === orderBy;
        const sortIcon = classNames('p-sortable-column-icon', {
            'pi pi-sort-amount-up-alt': sorted && sortOrder === 1,
            'pi pi-fw pi-sort-amount-down': sorted && sortOrder === -1,
            'pi pi-fw pi-sort-alt': sortOrder === 0 || !sorted
        });

        return (
            <div className="flex align-items-center" onClick={() => monitoringSort({'sortField': sortField})}>
                {field} <i className={sortIcon}></i>
            </div>
        );
    };

    const toggleTab = (tableName: string) => {
        setSelectedRowData(null);
        setActiveTab(tableName);
    };

    const isMobile = () => {
        return windowWidth <= 1248;
    }

    const renderActivityColumn = ({item}: any) => {
        return item?.callType === 'transferInternal' && !item.isDeclined ? (
            <div className="relative">
                <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                   style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
            </div>
        ) : item?.callType === 'transferInternal' && item.isDeclined ? (
                <div className="relative">
                    <i className="pi pi-reply p-2 bg-green-500 border-circle text-white"
                       style={{transform: 'rotate(90deg) scaleX(1) scaleY(-1)'}}></i>
                    <div className="absolute" style={{top: '-5px', left: '18px'}}>
                        <div className="border-circle relative"
                             style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                            <i className="pi pi-times text-white absolute left-50 top-50 kk"
                               style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                        </div>
                    </div>
                </div>) :

            item?.callType === 'transferExternal' && !item.isDeclined ? (
                <div className="relative">
                    <i className="pi pi-reply p-2 bg-primary border-circle text-white"
                       style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                </div>
            ) : item?.callType === 'transferInternal' && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-reply p-2 bg-primary border-circle text-white"
                           style={{transform: 'rotate(90deg) scaleX(-1) scaleY(-1)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>)
                : item?.callType === 'callback' ? (
                    <div className="relative">
                        <i className="pi pi-undo p-2 border-circle text-white" style={{backgroundColor: "#fda610"}}></i>
                    </div>
                ) : item?.callType === 'internal' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                    </div>
                ) : item?.callType === 'internal' && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-arrow-down-left p-2 border-circle bg-green-500 text-white"></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : item?.callType === 'external' && !item.isDeclined ? (
                    <>
                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                    </>
                ) : item?.callType === 'external' && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-arrow-up-right bg-primary p-2 border-circle text-white"></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : item?.callType === 'callbackExternal' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-undo p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative bg-primary"
                                 style={{width: '14px', height: '14px'}}>
                                <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : item?.callType === 'callbackInternal' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-undo p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative bg-green-500"
                                 style={{width: '14px', height: '14px'}}>
                                <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : (item?.callType === 'callbackInternal' || item?.callType === 'callbackExternal') && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-undo p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : item?.callType === 'consultationExternal' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative bg-primary"
                                 style={{width: '14px', height: '14px'}}>
                                <i className="pi pi-arrow-up-right text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>
                ) : item?.callType === 'consultationInternal' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative bg-green-500"
                                 style={{width: '14px', height: '14px'}}>
                                <i className="pi pi-arrow-down-left text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>
                ) : (item?.callType === 'consultationInternal' || item?.callType === 'consultationExternal') && item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white"
                           style={{background: 'rgb(253, 166, 16)'}}></i>
                        <div className="absolute" style={{top: '-5px', left: '18px'}}>
                            <div className="border-circle relative"
                                 style={{backgroundColor: 'red', width: '14px', height: '14px'}}>
                                <i className="pi pi-times text-white absolute left-50 top-50 kk"
                                   style={{fontSize: '10px', transform: 'translate(-50%, -50%)'}}></i>
                            </div>
                        </div>
                    </div>) : item?.callType === 'consultation' && !item.isDeclined ? (
                    <div className="relative">
                        <i className="pi pi-users p-2 border-circle text-white" style={{background: 'rgb(253, 166, 16)'}}></i>
                    </div>
                ) : <></>;
    };

    const handleResetPlayer = () => {
        dispatch({type: UPLOAD_AUDIO_LOADING, payload: false});
        dispatch(getAudioUrlForPlayer(null));
        setKey(key + 1);
    };

    const typeBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.ownerUserName}
                </span>
            </React.Fragment>
        );
    };

    const aniBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.ani || rowData.item?.aNI}
                </span>
            </React.Fragment>
        );
    };

    const typeOperatorTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.fullName}
                </span>
            </React.Fragment>
        );
    };


    const typeDirectionBodyTemplate = (rowData: DataRow) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.direction}
                </span>
            </React.Fragment>
        );
    };

    const campaignBodyTemplate = (rowData: DataRow) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.campaignFullName}
                </span>
            </React.Fragment>
        );
    };

    const queueBodyTemplate = (rowData: DataRow) => {
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {rowData.item?.queueFullName}
                </span>
            </React.Fragment>
        );
    };

    const callResetBodyTemplate = (rowData: any) => {
        const foundCallReset = callResetDictionary?.filter((item: any) => item?.dictionaryId === rowData?.item?.callReset);
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {foundCallReset?.[0]?.name}
                </span>
            </React.Fragment>
        );
    };

    const amdHangupBodyTemplate = (rowData: any) => {
        const foundAmdHangup = forcedClosingDictionary?.filter((item: any) => item?.dictionaryId === rowData?.item?.forcedClosing);
        return (
            <React.Fragment>
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {foundAmdHangup?.[0]?.name}
                </span>
            </React.Fragment>
        );
    };

    const renderStatusColumn = (rowData: any) => {
        let foundStatus = statusDictionary?.filter((status: any) => status?.dictionaryId === rowData?.item?.status);

        const statusMap: {
            [key: string]: string
        } = {
            101: 'p-badge status-active',
            103: 'p-badge status-success',
            104: 'p-badge status-unsuccessful',
            ACTION: 'p-badge status-action',
            102: 'p-badge status-processing'
        };

        const statusClass = statusMap[foundStatus?.[0]?.dictionaryId];

        return (
            <Badge value={foundStatus?.[0]?.name} className={`${statusClass} text-white customer-badge w-6 text-xs p-0`}
                   style={{maxWidth: '95px', minWidth: '95px'}}/>
        );
    };

    const amdHangupFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть:</div>
                <MultiSelect
                    value={options.value}
                    options={forcedClosingDictionary}
                    optionLabel='name'
                    optionValue={'dictionaryId'}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };
    const callResetFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Виберіть ініціатора</div>
                <MultiSelect
                    value={options.value}
                    options={callResetDictionary || null}
                    optionValue="dictionaryId"
                    optionLabel="name"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const statusFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть статус</div>
                <MultiSelect
                    value={options.value}
                    options={statusDictionary || null}
                    optionValue="dictionaryId"
                    optionLabel="name"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };

    const directionFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть статус</div>
                <MultiSelect
                    value={options.value}
                    optionValue="name"
                    options={queuesDirectoryDirectionType}
                    optionLabel="name"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"
                />
            </>
        );
    };
    const usersDataWithFullName = usersData?.map((userData: any) => {
        return {
            ...userData,
            fullName: `${userData?.lastName} ${userData?.firstName} ${userData?.middleName ? `${userData.middleName}` : ''}`
        }
    });

    const campaignListWithId = campaignList?.campaigns?.map((campaignData: any) => {
        return {
            ...campaignData,
            campaignWithId: `${campaignData.id} ${campaignData.name}`
        }
    });

    const queueListWithId = campaignList?.queues?.map((queueData: any) => {
        return {
            ...queueData,
            queueWithId: `${queueData?.id} ${queueData?.name}`
        }
    });

    const operatorItemTemplate = (item: any) => {
        return <div className="flex justify-content-between">
            <div>{item.fullName}</div>
            <div className="ml-3 text-sm">{!item?.isActive && 'Архівний'}</div>
        </div>;
    }

    const operatorFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="userName"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={usersDataWithFullName}
                    itemClassName="w-full operatorItem"
                    maxSelectedLabels={0}
                    itemTemplate={operatorItemTemplate}
                    optionLabel="fullName"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const ownerFilterTemplate = (options: any) => {
        let usersData: any
        if (!Array.isArray(usersDataWithFullName)) {
            console.error('usersDataWithFullName is not an array');
        } else {
            usersData = [
                {value: "", userName: ""},
                ...usersDataWithFullName
            ];
        }
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="userName"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={usersData}
                    maxSelectedLabels={0}
                    optionLabel="userName"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const campaignFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="id"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={campaignListWithId}
                    maxSelectedLabels={0}
                    optionLabel="campaignWithId"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const queueFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть:</div>
                <MultiSelect
                    value={options.value}
                    optionValue="id"
                    selectedItemsLabel={options.value ? `Обрано: ${options.value.length}` : ""}
                    filter
                    options={queueListWithId}
                    maxSelectedLabels={0}
                    optionLabel="queueWithId"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter"

                />
            </>
        );
    };

    const typeChannelItemTemplate = (option: any) => {
        let statusName = callTypeDictionary?.map((item: any) => {
            if (item.name === option) {
                return item.description
            }
        })
        return (
            <div className="p-multiselect-representative-option">
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {statusName}
                </span>
            </div>
        );
    }

    const selectedTypeChannelTemplate = (option: any) => {
        let statusName = callTypeDictionary?.map((item: any) => {
            if (item.name === option) {
                return item.description
            }
        })
        if (option) {
            return (
                <span style={{marginLeft: '.5em', verticalAlign: 'middle'}} className="image-text">
                    {statusName},
                </span>
            );
        }

        return "Оберіть тип";
    }

    const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть дату</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar className="mt-2" value={filterDateTime ? filterDateTime : null} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setFilterDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar className="mt-2" value={filterEndDateTime ? filterEndDateTime : null} onChange={(e: any) => {
                    setFilterEndDateTime(e.value)
                }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex max-w-15rem">
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">З*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={startTime ? startTime : null}
                            timeOnly
                            onChange={(e) => setStartTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">По*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={endTime ? endTime : null}
                            required
                            timeOnly
                            onChange={(e) => setEndTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
            </div>
        </>
    };

    const dateEndFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть дату</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar className="mt-2" value={filterFinishDateTime ? filterFinishDateTime : null}
                          onChange={(e: any) => {
                              options.filterCallback(e.value, options.index);
                              setFilterFinishDateTime(e.value)
                          }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar className="mt-2" value={filterFinishEndDateTime ? filterFinishEndDateTime : null}
                          onChange={(e: any) => {
                              setFilterFinishEndDateTime(e.value)
                          }} dateFormat="dd/mm/yy" placeholder="дд/мм/рр" mask="99/99/9999"/>
            </div>
            <div className="flex max-w-15rem">
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">З*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={startFinishTime ? startFinishTime : null}
                            timeOnly
                            onChange={(e) => setStartFinishTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
                <div className="md:col-6">
                    <div className="time-item">
                        <span className="title">По*</span>
                        <Calendar
                            appendTo="self"
                            id="icon"
                            value={endFinishTime ? endFinishTime : null}
                            required
                            timeOnly
                            onChange={(e) => setEndFinishTime(e.value)}
                            hourFormat="24"
                        />
                    </div>
                </div>
            </div>
        </>
    };

    const fullCallDurationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={fullCallDurationStartTime ? fullCallDurationStartTime : 0} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setfullCallDurationStartTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={fullCallDurationEndTime ? fullCallDurationEndTime : 0} onChange={(e: any) => {
                    setfullCallDurationEndTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const durationFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2" value={durationStartTime ? durationStartTime : 0}
                          onChange={(e: any) => {
                              options.filterCallback(e.value, options.index);
                              setDurationStartTime(e.value)
                          }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2" value={durationEndTime ? durationEndTime : 0}
                          onChange={(e: any) => {
                              setDurationEndTime(e.value)
                          }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const reactionClientFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionClientStartTime ? reactionClientStartTime : 0} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setReactionClientStartTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionClientEndTime ? reactionClientEndTime : 0} onChange={(e: any) => {
                    setReactionClientEndTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const reactionOperatorFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return <>
            <span className="title">Виберіть час:</span>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>З*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionOperatorStartTime ? reactionOperatorStartTime : 0} onChange={(e: any) => {
                    options.filterCallback(e.value, options.index);
                    setReactionOperatorStartTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
            <div className="flex field align-items-center max-w-15rem">
                <span className="title" style={{width: '35px'}}>По*</span>
                <Calendar timeOnly showSeconds className="mt-2"
                          value={reactionOperatorEndTime ? reactionOperatorEndTime : 0} onChange={(e: any) => {
                    setReactionOperatorEndTime(e.value)
                }} placeholder="00:00:00"/>
            </div>
        </>
    };

    const typeChannelFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <MultiSelect
                    value={options.value}
                    options={callTypeDictionary?.map((item: { name: any; }) => item.name)}
                    itemTemplate={typeChannelItemTemplate}
                    selectedItemTemplate={selectedTypeChannelTemplate}
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter mb-3"
                />
            </>
        );
    };
    const ownerTypeFilterTemplate = (options: any) => {
        return (
            <>
                <div className="mb-3 text-bold">Оберіть тип</div>
                <MultiSelect
                    value={options.value}
                    options={ownerTypeDictionary}
                    optionLabel="description"
                    optionValue="dictionaryId"
                    onChange={(e) => options.filterCallback(e.value, options.index)}
                    placeholder="нічого не обрано"
                    className="p-column-filter mb-3"
                />
            </>
        );
    };

    const BSessionIcon = () => {
        return <i className="pi pi-reply mr-2" style={{fontSize: '1rem', transform: 'rotate(270deg)'}}></i>
    }

    // const convertMillisecondsToTime = (totalMilliseconds: number): string => {
    //     const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    //     const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    //     const seconds = Math.floor((totalMilliseconds % (1000 * 60)) / 1000);
    //
    //     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    // };

    const convertSecondsToTime = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const firstLevelTableCase = (name: string, i: number) => {
        let itemName: any;

        switch (name) {
            case "ani":
                itemName = <Column
                    key={i}
                    field="item.ani"
                    filterField="ani"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву клiента"
                    filter
                    body={aniBodyTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    bodyClassName={(rowData) => rowData.item?.isMatchingSessionId ? 'your-custom-class' : ''}
                    header={'Клiент'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "ownerUserName":
                itemName = <Column
                    key={i}
                    field="item.ownerUserName"
                    filterField="ownerUserName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву власника"
                    filter
                    filterElement={ownerFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={'Власник дзвінка'}
                    body={typeBodyTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullName":
                itemName = <Column
                    field="item.fullName"
                    key={i}
                    sortable
                    filterField="fullName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву оператора"
                    filter
                    filterElement={operatorFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={renderHeader('Оператор', 'Username')}
                    body={typeOperatorTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "direction":
                itemName = <Column
                    field="item.direction"
                    key={i}
                    filterField="direction"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву оператора"
                    filter
                    filterElement={directionFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={'Тип Активності'}
                    body={typeDirectionBodyTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "campaignFullName":
                itemName = <Column
                    field="item.campaignId"
                    sortable
                    key={i}
                    filterField="campaignFullName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву кампанії"
                    filter
                    body={campaignBodyTemplate}
                    filterElement={campaignFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    header={renderHeader('ID / Назва Кампанii', 'CampaignId')}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "queueFullName":
                itemName = <Column
                    field="item.queueId"
                    sortable
                    key={i}
                    filterField="queueFullName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть назву черги"
                    filter
                    body={queueBodyTemplate}
                    filterElement={queueFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    header={renderHeader('ID / Назва Черги', 'QueueId')}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "status":
                itemName = <Column
                    field="item.status"
                    header={renderHeader('Статус', 'Status')}
                    sortable
                    key={i}
                    filterField="status"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterElement={statusFilterTemplate}
                    filter
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                    body={renderStatusColumn}
                />;
                break;
            case "callType":
                itemName = <Column
                    field="item.callType"
                    header={'Активність'}
                    key={i}
                    filterField="callType"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть ID кампанії"
                    filter
                    filterElement={typeChannelFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    body={renderActivityColumn}
                    filterMenuClassName={'filterStart'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "callResetName":
                itemName = <Column
                    field="item.callReset"
                    sortable
                    filterField="callResetName"
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filter
                    key={i}
                    filterElement={callResetFilterTemplate}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    header={renderHeader('Завершив', 'CallReset')}
                    body={callResetBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    filterMenuClassName={'filterStart'}
                />;
                break;
            case "forcedClosing":
                itemName = <Column
                    field="forcedClosing"
                    header={renderHeader('Причина завершення', 'ForcedClosing')}
                    sortable
                    key={i}
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterElement={amdHangupFilterTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    body={amdHangupBodyTemplate}
                    filterMenuClassName={'filterStart'}
                    filter
                    filterField="forcedClosing"
                />;
                break;
            case "start":
                itemName = <Column
                    field="item.start"
                    filterField="start"
                    key={i}
                    filterElement={dateFilterTemplate}
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterPlaceholder="Введіть ID кампанії"
                    filter

                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterMenuClassName={'filterStart'}
                    header={renderHeader('Початок', 'Start')}
                    sortable
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullCallDuration":
                itemName = <Column
                    key={i}
                    header={renderHeader('Тривалість дзвінка', 'FullCallDuration')}
                    className="justify-content-start"
                    body={(data) => data.item.fullCallDuration && data?.item?.fullCallDuration !== -1 ? convertSecondsToTime(data?.item.fullCallDuration) : data?.item?.fullCallDuration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="fullCallDuration"
                    filterElement={fullCallDurationFilterTemplate}
                />;
                break;
            case "duration":
                itemName = <Column
                    key={i}
                    header={renderHeader('Тривалість розмови', 'Duration')}
                    className="justify-content-start"
                    body={(data) => data?.item?.duration && data?.item?.duration !== -1 ? convertSecondsToTime(data?.item?.duration) : data?.item?.duration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="duration"
                    filterElement={durationFilterTemplate}
                />;
                break;
            case "reactionClient":
                itemName = <Column
                    key={i}
                    header={renderHeader('Реакція Клієнта', 'ReactionClient')}
                    className="justify-content-start"
                    body={(data) => data?.item?.reactionClient && data?.item?.reactionClient !== -1 ? convertSecondsToTime(data?.item?.reactionClient) : data?.item?.reactionClient === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="reactionClient"
                    filterElement={reactionClientFilterTemplate}
                />;
                break;
            case "reactionOperator":
                itemName = <Column
                    key={i}
                    header={renderHeader('Реакція Оператора', 'ReactionOperator')}
                    className="justify-content-start"
                    body={(data) => data?.item?.reactionOperator && data?.item?.reactionOperator !== -1 ? convertSecondsToTime(data?.item?.reactionOperator) : data?.item?.reactionOperator === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="reactionOperator"
                    filterElement={reactionOperatorFilterTemplate}
                />;
                break;
            case "ownerType":
                let foundOwnerType = (ownerTypeId: any) => {
                    let ownerType = ownerTypeDictionary?.filter((status: any) => status?.dictionaryId === ownerTypeId);
                    return ownerType?.[0]?.description;
                }

                itemName = <Column
                    key={i}
                    header={renderHeader('Тип власника', 'OwnerType')}
                    className="justify-content-start"
                    body={(data) => foundOwnerType(data?.item?.ownerType) || ''}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Виберіть власника"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="ownerType"
                    filterElement={ownerTypeFilterTemplate}
                />;
                break;
            case "end":
                itemName = <Column
                    key={i}
                    header={renderHeader('Завершення', 'End')}
                    className="justify-content-start"
                    body={(data) => data?.item?.end ? formatDateTime(data?.item?.end) : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    sortable
                    filterPlaceholder="Введіть час"
                    filter
                    showFilterMatchModes={false}
                    showFilterMenuOptions={false}
                    filterClear={filterClearTemplate}
                    filterApply={filterApplyTemplate}
                    filterField="end"
                    filterElement={dateEndFilterTemplate}
                />;
                break;
            default:
                itemName = itemName = <Column
                    key={i}
                    className="justify-content-start"
                    header={renderHeader('', '')}
                    body={''}
                />;
        }

        return itemName;
    }

    const secondLevelTableCase = (name: string, i: number) => {
        let itemName: any;
        switch (name) {
            case "ani":
                itemName = <Column
                    key={i}
                    className="justify-content-start"
                    field="item.ani"
                    body={aniBodyTemplate}
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "ownerUserName":
                itemName = <Column
                    key={i}
                    field="ownerUserName"
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={typeBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullName":
                itemName = <Column
                    key={i}
                    field="fullName"
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={typeOperatorTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "direction":
                itemName = <Column
                    key={i}
                    className="justify-content-start"
                    field="direction"
                    headerClassName='hidden'
                    body={typeDirectionBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "campaignFullName":
                itemName = <Column
                    key={i}
                    className="justify-content-start"
                    field="campaignId"
                    headerClassName='hidden'
                    body={campaignBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "queueFullName":
                itemName = <Column
                    key={i}
                    field="queueId"
                    className="justify-content-start queue-column"
                    body={queueBodyTemplate}
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "status":
                itemName = <Column
                    key={i}
                    className="justify-content-start status-column"
                    field="status"
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                    body={renderStatusColumn}
                />;
                break;
            case "callType":
                itemName = <Column
                    key={i}
                    field="callType"
                    className="justify-content-start channel-column"
                    body={renderActivityColumn}
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '150px'}}
                />;
                break;
            case "callResetName":
                itemName = <Column
                    key={i}
                    field="callResetName"
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={callResetBodyTemplate}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "forcedClosing":
                itemName = <Column
                    key={i}
                    field="forcedClosing"
                    className="justify-content-start"
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                    body={amdHangupBodyTemplate}
                />;
                break;
            case "start":
                itemName = <Column
                    key={i}
                    field="item.start"
                    className="justify-content-start"
                    sortable
                    headerClassName='hidden'
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "fullCallDuration":
                itemName = <Column
                    key={i}
                    header={'Тривалість дзвінка'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data.item.fullCallDuration && data?.item?.fullCallDuration !== -1 ? convertSecondsToTime(data?.item.fullCallDuration) : data?.item?.fullCallDuration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "duration":
                itemName = <Column
                    key={i}
                    header={'Тривалість розмови'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data?.item?.duration && data?.item?.duration !== -1 ? convertSecondsToTime(data?.item?.duration) : data?.item?.duration === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "reactionClient":
                itemName = <Column
                    key={i}
                    header={'Реакція Клієнта'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data?.item?.reactionClient && data?.item?.reactionClient !== -1 ? convertSecondsToTime(data?.item?.reactionClient) : data?.item?.reactionClient === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "reactionOperator":
                itemName = <Column
                    key={i}
                    header={'Реакція Оператора'}
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={(data) => data?.item?.reactionOperator && data?.item?.reactionOperator !== -1 ? convertSecondsToTime(data?.item?.reactionOperator) : data?.item?.reactionOperator === 0 ? '00:00:00' : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "ownerType":
                let foundOwnerType = (ownerTypeId: any) => {
                    let ownerType = ownerTypeDictionary?.filter((status: any) => status?.dictionaryId === ownerTypeId);
                    return ownerType?.[0]?.description;
                }

                itemName = <Column
                    key={i}
                    body={(data) => foundOwnerType(data?.item?.ownerType) || ''}
                    header={'Завершення'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}
                />;
                break;
            case "end":
                itemName = <Column
                    key={i}
                    header={'Завершення'}
                    headerClassName='hidden'
                    className="justify-content-start"
                    body={(data) => data?.item?.end ? formatDateTime(data?.item?.end) : '- - -'}
                    style={{cursor: 'pointer', width: `100%`, minWidth: '200px'}}

                />;
                break;
            default:
                itemName = itemName = <Column
                    key={i}
                    header={'Завершення'}
                    className="justify-content-start"
                    headerClassName='hidden'
                    body={''}
                />;
        }

        return itemName;
    }

    const rowExpansionBSessionTemplate = (data: DataRow) => {
        return (
            <DataTable
                value={data?.children}
                className="p-datatable-customers monitoring-table monitoring-table-td monitoring-Expansion"
                selectionMode="single"
                rowClassName={dynamicBSessionRowClass}
                selection={selectedBSessionRowExpansion}
                onRowClick={(e) => {
                    setSelectedRowData(null);
                    handleResetPlayer();
                    dispatch(getEventMonitorToUserDetail(e.data.item.id, jwtToken?.jwtToken));
                    if (!isMobile) {
                        if (props.profileMenuPin === true) {
                            setSelectedRowExpansion(e.data);
                            setVisible(true);
                        } else {
                            setSelectedRowExpansion(e.data);
                        }
                    } else {
                        setSelectedRowExpansion(e.data);
                        setVisible(true);
                    }
                }}
                dataKey="item.bSessionId"
            >
                <Column headerClassName={'hidden'} body={BSessionIcon}
                        style={{minWidth: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'end'}}/>
                {selectedColumns && selectedColumns?.length &&
                    selectedColumns.sort((a: any, b: any) => a.order - b.order)
                        .map((col: any, i: number) => (
                            isColumnSelected(col.name) && secondLevelTableCase(col.name, i)
                        ))}
            </DataTable>
        );
    };

    const sessionBodyIcon = (rowData: any) => {
        if (rowData?.children?.length) {
            return <></>
        }
    }

    const rowExpansionTemplate = (data: DataRow) => {
        return (
            <DataTable
                value={data?.children}
                className="p-datatable-customers monitoring-table monitoring-table-td monitoring-Expansion"
                selectionMode="single"
                selection={selectedSessionRowExpansion}
                rowExpansionTemplate={rowExpansionBSessionTemplate}
                expandedRows={expandedSessionRows}
                onRowToggle={(e) => setExpandedSessionRows(e.data)}
                onRowClick={(e) => {
                    handleResetPlayer();
                    dispatch(getEventMonitorToUserDetail(e.data.item.id, jwtToken?.jwtToken));
                    setSelectedRowData(null);
                    if (!isMobile) {
                        if (props.profileMenuPin === true) {
                            setSelectedRowExpansion(e.data);
                            setVisible(true);
                        } else {
                            setSelectedRowExpansion(e.data);
                        }
                    } else {
                        setSelectedRowExpansion(e.data);
                        setVisible(true);
                    }
                }}
                dataKey="item.bSessionId"
            >
                <Column rowReorderIcon={'pi pi-close'} headerClassName={'hidden'} expander={allowExpansion}
                        style={{minWidth: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'end'}}/>
                <Column headerClassName={'hidden'} body={sessionBodyIcon} expander={allowExpansion}
                        style={{minWidth: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}/>
                {selectedColumns && selectedColumns?.length &&
                    selectedColumns?.sort((a: any, b: any) => a.order - b.order)
                        .map((col: any, i: number) => (
                            isColumnSelected(col?.name) && secondLevelTableCase(col?.name, i)
                        ))}
            </DataTable>
        );
    };

    const allowExpansion = (rowData: DataRow) => {
        return rowData.children?.length || false;
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const dynamicRowClass = (data: { item: any; }) => {
        if (data?.item?.bSessionId === selectedRowData?.item?.bSessionId) {
            return 'mainRowMonitoringTable p-highlight';
        }
        return 'mainRowMonitoringTable';
    };

    const dynamicBSessionRowClass = () => {
        return 'BSessionRow';
    };

    const isColumnSelected = (columnName: string) => {
        return selectedColumns?.some((item: { name: string }) => item?.name === columnName);
    };
    const renderTable = () => {
        return (
            <div className="layout-dashboard">
                <div className="flex flex-wrap col-12 md:col-12">
                    <div
                        className={classNames('card widget-table mr-4 col-12 transition-duration-300 monitoring-table align-self-start monitoringLoader', {'': (selectedRowData || selectedRowExpansion) && !isMobile(), 'md:col-12': !(selectedRowData || selectedRowExpansion)})}>
                        <MonitoringListNav userSortFields={userSortFields?.fields} sortData={sortData}
                                           resetSorting={handleReset} toggleTab={toggleTab}
                                           activeTab={activeTab}/>
                        <DataTable
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={rowExpansionTemplate}
                            scrollable
                            scrollHeight={(selectedRowData || selectedRowExpansion) && !isMobile() ? '40vh' : '70vh'}
                            ref={dataTableRef}
                            rows={10}
                            onSort={monitoringSort}
                            filters={clearFilters}
                            loading={loader}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            value={formattedData}
                            className="p-datatable-customers monitoring-table monitoring-table-rowGroup"
                            rowClassName={dynamicRowClass}
                            selectionMode="single"
                            emptyMessage="Нічого не знайдено"
                            rowHover={false}
                            onFilter={customFilter}
                            onRowClick={(e) => {
                                setSelectedRowExpansion(null);
                                handleResetPlayer();
                                dispatch(getEventMonitorToUserDetail(e.data.item.id, jwtToken?.jwtToken));
                                if (!isMobile) {
                                    if (props.profileMenuPin === true) {
                                        setSelectedRowData(e.data);
                                        setVisible(true);
                                    } else {
                                        setSelectedRowData(e.data);
                                    }
                                } else {
                                    setSelectedRowData(e.data);
                                    setVisible(true);
                                }
                            }} dataKey="item.bSessionId"
                        >
                            <Column rowReorderIcon={'pi pi-close'} expander={allowExpansion}
                                    style={{minWidth: '6rem'}}/>
                            {selectedColumns && selectedColumns?.length &&
                                selectedColumns?.sort((a: any, b: any) => a.order - b.order)
                                    .map((col: any, i: number) => (
                                        isColumnSelected(col?.name) && firstLevelTableCase(col?.name, i)
                                    ))}
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={paginationSize}
                            totalRecords={totalItems}
                            rowsPerPageOptions={[5, 10, 15, 25, 50]}
                            onPageChange={onPageChange}
                            className="border-top-1 border-bottom-1"
                            template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                        />
                    </div>
                    {(selectedRowData || selectedRowExpansion) && monitoringData && (
                        <>
                            {windowWidth > 1248 && <div
                                className={classNames('card widget-table col-12 md:col-12 align-self-start sticky top-0')}>
                                <MonitoringListDetails setSelectedRowData={setSelectedRowData}
                                                       setSelectedRowExpansion={setSelectedRowExpansion}
                                                       handleResetPlayer={handleResetPlayer}/>
                            </div>}

                            {windowWidth <= 1248 && <div>
                                <Dialog visible={visible} className="monitoring-info" onHide={() => setVisible(false)}>
                                    <MonitoringListDetails setSelectedRowData={setSelectedRowData}
                                                           setSelectedRowExpansion={setSelectedRowExpansion}
                                                           handleResetPlayer={handleResetPlayer}/>
                                </Dialog>
                            </div>}
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            {renderTable()}
        </div>
    );
};

export default MonitoringBranches;
